import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Features = (props) => {
  return (
    <div>
      <Header sectionHeading='Features'></Header>
    <div class="align-items-center my-lg text-center">
      <div class="container col-sm-9">
        <h3>
          We Help SaaS, PaaS Platforms, Cloud Solutions, E-commerce Sites,
          Anyone on the Cloud
        </h3>
        <div class="container entry-content mt-4">
          <p>
            MaxSpotter is a cloud workload optimization tool focused on
            analyzing spot markets price history, spot availability, spot
            termination likelihood for long running spot instances. It uses
            state-of-the-art advanced machine learning techniques for bid price
            predictions and It’s available for the AWS for the moment, however
            Azure and Google Cloud Platforms are on the way. Platform owners
            need cost savings, so we empower them to seemlessly integrate
            MaxSpotter and start savings in couple of minutes.
          </p>
        </div>
      </div>
    </div>
    <section class="jumbotron sub-page text-center d-flex align-items-center">
      <div class="container">
        <h1 class="display-5">
          Manage your workloads efficiently with minimum costs
        </h1>
      </div>
    </section>
    <Footer></Footer>
    </div>
  );
}

export default Features;