import React, { useState } from 'react';

const ConsentCheckboxes = (props) => {
  const [consentChecked, setConsentChecked] = useState(props.defaultConsent || false);
  const [communicationsChecked, setCommunicationsChecked] = useState(props.defaultCommunications || false);

  const handleTermsCheck = (event) => {
    setConsentChecked(event.target.checked);
    props.setConsentChecked(event.target.checked);
  }

  const handlePrivacyCheck = (event) => {
    setCommunicationsChecked(event.target.checked);
    props.setCommunicationsChecked(event.target.checked);
  }

  return (
    <div>
      <div className="col-12 align-items-center d-flex">
        <div className="forrm-check pl-4 py-3">
        <input type="checkbox" class="form-check-input" checked={consentChecked} onChange={handleTermsCheck}/>
        <label class="form-check-label text-left">I agree to allow Reservops.ai to store and process my usage data for analytics.</label>
        </div>
      </div>
      <div className="col-12 align-items-center d-flex">
        <div className="forrm-check pl-4 pb-3">
        <input disabled={!consentChecked} type="checkbox" class="form-check-input" checked={communicationsChecked} onChange={handlePrivacyCheck}/>
        <label style={{opacity: consentChecked ? '1' : '0.5'}} class="form-check-label text-left">I agree to receive marketing offers, discounts or news through emails from Reservops.ai.</label>
        </div>
      </div>
    </div>
  );
}

export default ConsentCheckboxes;
