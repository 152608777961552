/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import autoPurchaseIcon from '../images/auto-purchase-icon.png';
import autoRenewIcon from '../images/auto-renew-icon.png';
import autoExchangeIcon from '../images/auto-exchange-icon.png';

const WhyBusinessComponents2 = (props) => {

  const renderRegularCards = (
      <div className="row d-flex justify-content-center col-12 p-0 m-0">
          <div className="col-12 col-md-6 col-lg-3 pb-4">
            <div className="card pricing mx-3 ml-md-0 m-lg-0 h-100">
                <div className="col-12">
                  <img width="100%" height="auto" alt="" src={autoPurchaseIcon}/>
                  <h4 className="mb-4 mt-4"><b>Auto Purchase</b></h4>
                  <p>Automatically purchase optimized Reservation plans or use 1-Click execution for plans pushed to you when we see opportunities to save.</p>
                </div>
            </div>
          </div>
          <div  className="col-12 col-md-6 col-lg-3 pb-4">
          <div className="card pricing mx-3 ml-md-0 m-lg-0 h-100">
                <div className="col-12">
                  <img width="100%" height="auto" alt="" src={autoRenewIcon}/>
                  <h4 className="mb-4 mt-4"><b>Auto Renew</b></h4>
                  <p>Never forget to renew expiring reservations to the most optimal new configuration using our renewal modeling & auto-execution.</p>
                </div>
           </div>
          </div>
          <div  className="col-12 col-md-6 col-lg-3 pb-4 mt-3">
          <div className="card pricing mx-3 ml-md-0 m-lg-0 h-100">
                <div className="col-12">
                  <img className="mt-5" width="100%" height="auto" alt="" src={autoExchangeIcon}/>
                  <h4 className="mb-4 mt-5"><b>Auto-Exchange</b></h4>
                  <p>Automatically exchange Convertible Reserved Instances to ensure continuous savings maximization as your usage patterns change.</p>
                </div>
            </div>
          </div>
        </div>
  );

  return (
    <div id="who-is-it-for" className="section bg-light">
    <div className="container">
      <div className="row">
        {props.headerText && <div style={{color: 'white'}} className="col-12 section-title text-center mb-5">
          <h3>{props.headerText}</h3>
          <p>
          </p>
        </div>}
        <div>
        {props.disabledCard > 0 ? renderRegularCards  : renderRegularCards}
        </div>
      </div>
    </div>
  </div>
  ); 
}

export default WhyBusinessComponents2;
