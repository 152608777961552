/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import { Link } from 'react-router-dom';

const styles = {
  formField: {
    width: '100%',
    height: 'auto',
  },
  headingStyle: {
    textAlign: 'center',
    paddingTop: '2em',
    marginBottom: '2em',
  }
};

const PricingSection = (props) => {

  const [billing, setBilling] = useState(3000);
  const [offerAmount, setOfferAmount] = useState(49);
  const [isEnterprise, setEnterprise] = useState(false);

  const handleBillingChange = (event) => {
    setBilling(event.target.value);
    if (event.target.value <= 5000) {
      setOfferAmount(49);
      setEnterprise(false)
    } else if (event.target.value <= 20000) {
      setOfferAmount(149);
      setEnterprise(false)
    } else if (event.target.value <= 100000) {
      setOfferAmount(199);
      setEnterprise(false)
    } else {
      setEnterprise(true);
    }
  };

  return (<div className="container">
    <div className="row">
      <div className="col-12 col-lg-6 px-1 px-lg-5">
        <div className="card pricing">
          {/* <span className="badge-enterprise badge-enterprise-top-right"></span> */}
          <div className="card-body text-center">
            {/* <h4 className="mb-4">Early Access Deal</h4> */}
            <div className="row">
              <div className="col-12 d-flex align-items-center">
                  <div className="container p-0">
                    <h3>Start Up Plan</h3>
                    <h4>Free Tier</h4>
                  </div>
              </div>
              
              <div className="col-12 d-flex align-items-center p-0">
                <div className="container p-0">
                  <h5>NO credit card required</h5>
                  <br></br>
                  <br></br>
                  <h3>Free</h3>
                  <div className="pt-5">
                      <a
                        className="btn btn-xl btn-primary"
                        href={`${process.env.REACT_APP_DASHBOARD_LINK}/register` || ''}
                        id="calendly-trigger"
                      >
                        Get Started for Free
                      </a>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="col-12">
                <ul className="list-unstyled">
                <hr/>
                  <li>Start Seeing Your Overall Spendings</li>
                  <li>See Your Potential Savings</li>
                  <li>See Your Reservation Coverage</li>
                  <li>See Your Detailed Monthly Bill Trends</li>
                  {/* <li>No strings attached</li> */}
                  <li><b>Limited</b> Reservation Recommendations</li>
                  <hr/>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="col-12 col-lg-6 px-1 px-lg-5">
      
      <div className="card pricing">
        {/* <span className="badge-enterprise badge-enterprise-top-right"></span> */}
        <div className="card-body text-center">
          {/* <h4 className="mb-4">Early Access Deal</h4> */}
          <div className="row px-0 px-lg-5">
            <div className="col-12 d-flex align-items-center p-0">
                <div className="container p-0">
                  <h3>Fixed Flat Rate</h3>
                  <h4>Premium Tier</h4>
                </div>
            </div>
            <div className="col-12 d-flex align-items-center p-0">
              <div className="container p-0">
                <h5>What is your monthly AWS cost?</h5>
                <div className="row d-flex justify-content-center">
                  <div className="col col-9 d-flex">
                    <input 
                      style={styles.formField} 
                      className="form-group form-control formField" 
                      type="number" 
                      placeholder="AWS Cost" 
                      value={billing} 
                      onChange={handleBillingChange}
                      required
                    />
                  </div>
                  <div className="col-3">
                    <p style={{textAlign: 'left', lineHeight: '45px'}} className="p-0 m-0">$/mo</p>
                  </div>
                </div>
                {isEnterprise 
                  ? <Link to='/contact?origin=cookie-policy'>
                      <a
                        className=""
                        href=""
                      >
                        <h4>Contact Us</h4> 
                      </a>
                    </Link>
                  : <h4>{offerAmount} $ / mo</h4> 
                }
                <div className="pt-5">
                    <a
                      className="btn btn-xl btn-primary"
                      href={`${process.env.REACT_APP_DASHBOARD_LINK}/register` || ''}
                      id="calendly-trigger"
                    >
                      Get Started for Free
                    </a>
                </div>
              </div>
            </div>
            <br></br>
            <div className="col-12">
              <ul className="list-unstyled">
              <hr/>
                  <li>Start Seeing Your Overall Spendings</li>
                  <li>See Your Potential Savings</li>
                  <li>See Your Reservation Coverage</li>
                  <li>See Your Detailed Monthly Bill Trends</li>
                  <li><b>Full</b> Reservation Recommendations</li>
                  <hr/>
                  <li>Reservation Automated Management</li>
                  {/* <li>No credit card required</li> */}
                  <li>Actionable Notifications</li>
                  <li>Cost Segments and Budget Tracking</li>
                  <li>Anomaly Detector and Idle Resources</li>
                  <li>24/7 enterprise-grade support</li>
                  <li>Dedicated Slack channel support</li>
              </ul>
            </div>
            
          </div>
        </div>
      </div>
      </div>
    </div>
</div>)
};

export default PricingSection;