import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link, useLocation, useHistory } from 'react-router-dom';
import BillingSlider from '../components/BillingSlider';

const styles= {
  headingContainer: {
    margin: '4em auto'
  },
  sliderTitle: {
    textAlign: 'center',
    marginBottom: '2em',
  },
  nextBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2em'
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const EarlyAccessThree = (props) => {
  const query = useQuery();
  const history = useHistory();
  const origin = query.get('origin');
  const type = query.get('type');
  const platform = (query.get('platform'));
  const instanceCount = Number(query.get('instanceCount'));

  if (!platform || !instanceCount) {
    history.replace('/');
  }
  
  const [billing, setBilling] = useState(3000);
  
  const handleBillingChange = (value) => {
    setBilling(value);
  };

  return (
    <div>
      <Header
        sectionHeading='Monthly Bills' 
        
        sectionBodyText={`How much do you pay monthly?`}
      />
      <div className="d-flex container  justify-content-center align-items-center" style={styles.headingContainer}>
        <div className="col-8">
        <BillingSlider
              platform={platform || ''}
              setBilling={handleBillingChange}
        />
        <Link to={`/get-started-summary?platform=${platform}&instanceCount=${instanceCount}&billing=${billing}&origin=${origin}${type ? `&type=${type}` : ''}`}>
        <button
            style={styles.nextBtn} 
            className="btn btn-xl btn-outline-primary-maxspotter mx-auto mb-3"
          >Next</button>
        </Link> 
        </div>
      </div>
      <Footer></Footer>
    </div>
  ); 
}

export default EarlyAccessThree;
