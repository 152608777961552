/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const StatsSection = (props) => {

  const renderCustomCards = (
      <div className="row d-flex justify-content-center col-12 p-0 m-0">
          {props.disabledCard === 1
            ? null
            : <div className="col-12 col-md-6 col-lg-4 pb-4">
              <div className="card pricing mx-2 ml-md-0 m-lg-0 h-100">
                <div className="card-body h-100 px-0">
                  <h4 className="mb-4">500K+ $ Saved By Our Customers</h4>
                </div>
              </div>
            </div>
          }
          {props.disabledCard === 2
            ? null
            : <div style={{opacity: 0.7}} className="col-12 col-md-6 col-lg-4 pb-4">
              <div className="card pricing mx-2 ml-md-0 m-lg-0 h-100">
                <div className="card-body h-100 px-0">
                  <h4 className="mb-4">10K+ Cloud Reservations Managed</h4>
                </div>
              </div>
            </div>
          }
          {props.disabledCard === 3
            ? null
            : <div style={{opacity: 0.7}} className="col-12 col-md-6 col-lg-4 pb-4">
            <div className="card pricing mx-2 ml-md-0 m-lg-0 h-100">
                <div className="card-body h-100 px-0">
                  <h4 className="mb-4">AI Assistant Used 1K+ Times</h4>
                </div>
              </div>
            </div>
          }
        </div>
  );

  const renderRegularCards = (
      <div className="row d-flex justify-content-center col-12 p-0 m-0">
          <div className="col-12 col-md-6 col-lg-3 pb-4">
            <div className="card pricing mx-3 ml-md-0 m-lg-0 h-100">
                <h4 className="mb-4"><b>500K+ $</b> Saved By Our Customers</h4>
            </div>
          </div>
          <div  className="col-12 col-md-6 col-lg-3 pb-4">
          <div className="card pricing mx-3 ml-md-0 m-lg-0 h-100">
                <h4 className="mb-4"><b>10K+</b> Cloud Reservations Managed</h4>
           </div>
          </div>
          <div  className="col-12 col-md-6 col-lg-3 pb-4">
          <div className="card pricing mx-3 ml-md-0 m-lg-0 h-100">
                <h4 className="mb-4">AI Assistant Used <b>1K+</b> Times and Customers Started Saving Less Than <b>a Minute</b></h4>
            </div>
          </div>
        </div>
  );

  return (
    <div id="who-is-it-for" className="section bg-gradient-primary">
    <div className="container">
      <div className="row">
        {props.headerText && <div style={{color: 'white'}} className="col-12 section-title text-center mb-5">
          <h3>{props.headerText}</h3>
          <p>
          </p>
        </div>}
        <div>
        {props.disabledCard > 0 ? renderCustomCards : renderRegularCards}
        </div>
      </div>
    </div>
  </div>
  ); 
}

export default StatsSection;
