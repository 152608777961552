import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Faq from 'react-faq-component';


const data = {
  //title: "FAQ (How it works)",
  rows: [
      {
          title: "What is Reservops.ai?",
          content: `Reservops.ai is a SaaS platform for AWS cost visibility and cost saving`,
      },
      {
          title: "How Reservops.ai saves cost?",
          content:
              "Reservops.ai recommends the best options for reservations and commitment for EC2, ElasticSearch, ElastiCache, Redshift and RDS",
      },
      {
          title: "Does Reservops.ai access our AWS infrastructure: servers, data etc.?",
          content: `No. Provides user interface to manage your reservation contracts and commitments`,
      },
      {
        title: "How Reservops.ai access our system?",
        content: `Reservops.ai uses AWS best practices to access your cloud account with minimum permissions without any infrastruce access`,
      },
      {
        title: "How can we onboard our system to Reservops.ai?",
        content: `Reservops.ai has 2 minutes guided onboarding steps, you need to install CloudFormation stack which is easily auditable by the Engineering Teams`,
      },
  ],
};

const styles = {
  // bgColor: 'white',
  // titleTextColor: "grey",
  rowTitleColor: "black",
  rowContentColor: 'grey',
  // arrowColor: "red",
};

const config = {
  animate: true,
  //arrowIcon: "",
  tabFocus: true
};


const FAQ = (props) => {
  return (
      <div >
        <Header sectionHeading='Frequently Asked Questions'></Header>
        <ul></ul>
        <ul>
            <Faq
                data={data}
                styles={styles}
                config={config}
            /> 
         </ul>
         <ul></ul>
         <Footer></Footer>
      </div>
  );
}

export default FAQ;