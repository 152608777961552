import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link, useLocation, useHistory } from 'react-router-dom';
import InstanceNumberSlider from '../components/InstanceNumberSlider';

const styles= {
  headingContainer: {
    margin: '4em auto'
  },
  sliderTitle: {
    textAlign: 'center',
    marginBottom: '2em',
  },
  nextBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2em'
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const EarlyAccessTwo = (props) => {
  const query = useQuery();
  const history = useHistory();
  const origin = query.get('origin');
  const type = query.get('type');
  const platform = (query.get('platform'));
  if (!platform) {
    history.replace('/');
  }
    
  const [instanceCount, setInstanceCount] = useState(222);
  
  const handleInstanceCountChange = (value) => {
    setInstanceCount(value);
  };

  return (
    <div>
      <Header
        sectionHeading='Instance Count' 
        
        sectionBodyText={`How many instances running on ${platform || ''}?`}
      />
      <div className="d-flex container  justify-content-center align-items-center" style={styles.headingContainer}>
        <div className="col-8">
        <InstanceNumberSlider 
          setInstanceCount={handleInstanceCountChange}
          platform={platform || ''}
        />
        <Link to={`/get-started-cost?platform=${platform}&instanceCount=${instanceCount}&origin=${origin}${type ? `&type=${type}` : ''}`}>
        <button 
            style={styles.nextBtn} 
            className="btn btn-xl btn-outline-primary-maxspotter mx-auto mb-3"
          >Next</button>
        </Link> 
        </div>
      </div>
      <Footer></Footer>
    </div>
  ); 
}

export default EarlyAccessTwo;
