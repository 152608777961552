/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import StatsSection from '../components/StatsSection';
import awsLogo from '../images/aws_logo.png';
import azureLogo from '../images/azure-logo.png';
import gcpLogo from '../images/gcp-logo.png';
import alibabaLogo from '../images/alibaba-logo.png';
import awsEc2 from '../images/aws_ec2.png';
import awsEbs from '../images/aws_ebs.png';
import awsEcs from '../images/aws_ecs.png';

const styles = {
  platformLogo: {
    maxWidth: '100%',
    height: 'auto',
    opacity: '0.3',
    cursor: 'pointer'
  },
  platformLogoActive: {
    maxWidth: '100%',
    height: 'auto',
    cursor: 'pointer',
    // border: '1px solid #194b88'
  },
  platformOther: {
    maxWidth: '100%',
    height: '100%',
    opacity: '0.3',
    cursor: 'pointer',
    fontSize: '1.25em',
    lineHeight: '100%',
    margin: '0',
    textAlign: 'center',
    // color: '#194b88',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Product Sans, helvetica',
    marginTop: '-0.25em'
  },
  platformOtherActive: {
    maxWidth: '100%',
    height: '100%',
    cursor: 'pointer',
    fontSize: '1.25em',
    lineHeight: '100%',
    margin: '0',
    textAlign: 'center',
    // color: '#4CA5DC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontFamily: 'Product Sans, helvetica',
    marginTop: '-0.25em'
  },
  platformLogoHeader: {
    maxWidth: '100%',
    height: 'auto',
    opacity: '0.4'
  },
  platformHeaderLabel: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '16px',
    marginTop: '0.5em'
  },
  platformHeaderLabelText: {
    fontSize: '6px'
  },
  platformHeaderIcon: {
    maxWidth: '5rem',
  },
  otherPlatformFormField: {
    width: '100%',
  },
};

const Developers = (props) => {

  return (
    <div>
      <Header sectionHeading='Web Developers' />
    <div className="row container col-12 d-flex justify-content-center align-items-center p-3 my-3">
      <div className="row container d-flex justify-content-center align-items-center mb-3">
        <div className="col-9">
        <div className="row d-flex justify-content-center align-items-center my-2">
          <div className="col-3 d-none d-md-block p-0 m-0">
              <img 
                width="100%"
                src={awsLogo}
                alt="aws"
              />
            </div>
            <div className="col-12 col-md-9">
            <h2>Amazon Web Services</h2>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-9">
          <h5>
            <img src={awsEc2} alt="ec2" style={{maxHeight: '30px'}}/>
             <span className='ml-3'>Amazon Elastic Computing Cloud</span>
          </h5>
          <p>
            Computing resources are needed most. Use MaxSpotter to safely manage spot instances and reduce your EC2 costs drastically. 
          </p>
          <h5>
            <img src={awsEbs} alt="ec2" style={{height: '30px'}}/>
             <span className='ml-3'>Amazon Elastic Bean Stalk</span>
          </h5>
          <p> 
            One of the best services AWS gives is EBS. Easy to setup and easy to scele up. Even though your page does not have any traffic still running some instances just in case, right?
            Use MaxSpotter to save whether you are trying to get traffic or scaling up. Reduce your costs drastically. 
          </p>
          <h5>
            <img src={awsEcs} alt="ec2" style={{maxHeight: '30px'}}/>
             <span className='ml-3'>Amazon Elastic Container Service</span>
          </h5>
          <p>
            Deploying and scaling using containers is great. However all the required instances to run your web application costs. Sometimes costs a lot.
            Use MaxSpotter to save when you need more instances to support your website. Reduce your costs drastically.
          </p>
        </div>
      </div>
      <div className="row container d-flex justify-content-center align-items-center mb-5">
                <a
                  className="btn btn-xl btn-primary"
                  href={`${process.env.REACT_APP_DASHBOARD_LINK}/register` || ''}
                  id="calendly-trigger"
                >
                  Get Started
                </a>
      </div>
      <div className="row container d-flex justify-content-center align-items-center mb-3">
      <div className="row col-lg-9">
      <div className="col-lg-4 col-md-4 col-4 p-lg-5 p-md-5 p-sm-3">
        <img 
          style={styles.platformLogoHeader}
          src={azureLogo}
          alt='azure'
        />
      </div>
      <div className="col-lg-4 col-md-4 col-4 p-lg-5 p-md-5 p-sm-3">
        <img 
          style={styles.platformLogoHeader}
          src={gcpLogo}
          alt='gcp'
        />
      </div>
      <div className="col-lg-4 col-md-4 col-4 p-lg-5 p-md-5 p-sm-3">
        <img 
          style={styles.platformLogoHeader}
          src={alibabaLogo}
          alt='alibaba'
        />
      </div>
      <div className="col-12" style={styles.platformHeaderLabel}>
          <p style={styles.platformHeaderLabelText} className="btn btn-sm btn-outline-primary">Coming Soon</p>
        </div>
      </div>
  </div>
    </div>
    <StatsSection headerText={'Who else should use MaxSpotter?'} disabledCard={1}></StatsSection>
    <Footer></Footer>
    </div>
  );
}

export default Developers;