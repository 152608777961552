/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import differentsImg from "../images/anayticsbestrong.svg";
import { Link } from 'react-router-dom';

const HowIsItDifferent = (props) => {
  return (
    <div>
      <Header sectionHeading='How is it different?' />
      <div className="align-items-center my-lg text-center">
      <div className="container">
        <h4>
          What Sets MaxSpotter Apart from Spot Fleet AWS Offering
        </h4>
        <div className="entry-content text-left mt-4">
          <p>
            The spot fleets are groups of spot instances of different types,
            managed much like AutoScaling groups, but with a different API and
            configuration mechanism. Each instance type needs to be explicitly
            configured with a certain bid price and weight, so that the group's
            capacity can be scaled out over various instance types.
          </p>
          <p>
            These groups are quite resilient because they are usually spread
            over multiple spot instance types, so it's quite unlikely that the
            price will surge on all of them at once. But much like the default
            AutoScaling spot mechanism they are also unable to fall back to
            on-demand capacity in case the prices surge across all their
            instance types. MaxSpotter will also try to avoid using all
            instances of the same type, in many cases, with enough capacity by
            spreading over three or four different spot market price graphs,
            which in addition to the on-demand fallback capability should be
            also quite resilient in the event of spot terminations.
          </p>
        </div>
      </div>
    </div>
    <div className="section my-lg">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <img
              src={differentsImg}
              alt="differents"
              width="90%"
            />
          </div>
          <div className="col-lg-7 ml-5 entry-content">
            <h4>Spot Allocation</h4>
            <p>
              Coming soon
            </p>
            <h4>Fallback on-demand</h4>
            <p>
              Coming soon
            </p>
            <h4>Preferred Instances or Availability Zones</h4>
            <p>
              Coming soon
            </p>
            <h4>Infrastructure as a code first</h4>
            <p>
              Coming soon
            </p>
            <h4>
              Learn how MaxSpotter can work for your cloud infrastructure, or a
              check out our features <Link to='/features'><a href="">here</a></Link>.
            </h4>
          </div>
        </div>
      </div>
    </div>
      <Footer></Footer>
    </div>
  );
}

export default HowIsItDifferent;