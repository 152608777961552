/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import tagIcon from '../images/tag-icon.png';
import segmentsIcon from '../images/segments-icon.png';
import groupsIcon from '../images/groups-icon.png';


const WhyBusinessComponents4 = (props) => {

  const renderRegularCards = (
    <div className="row d-flex justify-content-center col-12 p-0 m-0">
        <div className="col-12 col-md-6 col-lg-3 pb-4">
          <div className="card pricing mx-3 ml-md-0 m-lg-0 h-100">
            <div className="col-12">
              <img  width="100%" height="auto" alt="" src={tagIcon}/>
              <h4 className="mb-4 mt-4"><b>Tag Manager</b></h4>
              <span>Automated tagging reduces the amount of time spent attributing costs and reduces the errors found in manual tagging.</span>
            </div>
          </div>
        </div>
        <div  className="col-12 col-md-6 col-lg-3 pb-4">
        <div className="card pricing mx-3 ml-md-0 m-lg-0 h-100">
            <div className="col-12">
              <img width="100%" height="auto" alt="" src={segmentsIcon}/>
              <h4 className="mb-4 mt-4"><b>Segments</b></h4>
              <span>Break down your infrastructure by accounts, regions, services, instance types, and operating systems.</span>
            </div>
         </div>
        </div>
        <div  className="col-12 col-md-6 col-lg-3 pb-4">
        <div className="card pricing mx-3 ml-md-0 m-lg-0 h-100">
            <div className="col-12">
              <img width="100%" height="auto" alt="" src={groupsIcon}/>
              <h4 className="mb-4 mt-4"><b>Groups</b></h4>
              <span>Create groups around teams and business units, automatically alerting relevant team members on budgets, forecasts, and costs.</span>
            </div>
          </div>
        </div>
      </div>
);

  return (
    <div id="who-is-it-for" className="section bg-primary">
    <div className="container">
      <div className="row">
        {props.headerText && <div style={{color: 'white'}} className="col-12 section-title text-center mb-5">
          <h3>{props.headerText}</h3>
          <p>
          </p>
        </div>}
        <div>
        {props.disabledCard > 0 ? renderRegularCards  : renderRegularCards}
        </div>
      </div>
    </div>
  </div>
  ); 
}

export default WhyBusinessComponents4;
