/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  title: {
    textAlign: 'left',
    color: 'rgba(0,0,0,0.5)'
  },
  description: {
    opacity: '0.8',
  }
};

const HeaderNavDropdownItem = (props) => {

  console.log(props.title, props.link);

  return (<Link to={{pathname: props.link}} target={props.external && 'blank'}>
    <div style={{cursor: "pointer"}} className="row col col-12 d-flex w-100 dropdown-item mx-0">
    <div className="col col-12 d-flex">
    <span style={styles.title} className="px-0" href="">
      {props.title}
    </span>
    </div>
    <div className="col col-12 d-flex">
      <p style={styles.description}>{props.description}</p>
      </div>
  </div>  
  </Link>
  );
}

export default HeaderNavDropdownItem;