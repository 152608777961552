import React from 'react';
import ContentBandSection from '../components/ContentBandSection';

const ContentBand = (props) => {

  return (
    <header
      data-parallax="scroll"
      data-speed="0.5"
      data-image-src="images/hero.jpg"
    >
     <ContentBandSection 
        sectionHeading={props.sectionHeading}
        sectionBodyHeading={props.sectionBodyHeading}
        sectionBodyText={props.sectionBodyText}
        hasFeaturesSection={props.hasFeaturesSection}
        hasPricingSection={props.hasPricingSection}
      />
    </header>
  );
}

export default ContentBand;