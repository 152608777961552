/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/reservopsai_logo.png';
import HeaderNavDropdown from './HeaderNavDropdown';
import HeaderNavDropdownItem from './HeaderNavDropdownItem';

const HeaderNav = (props) => {
  const dropdownContent = {
    whyReservops: {
      items: [
        {
          title: 'Business Teams',
          link: 'why-business-teams',
        },
        {
          title: 'Engineering Teams',
          link: 'why-engineering-teams',
        }
      ],
    },
    platform: {
      items: [
        {
          title: 'AI-Assistant',
          /*description: 'Save 35% in average within 2 minutes with AI-assistant',*/
          link: 'platform',
        },
        {
          title: 'Reservations',
          /*description: 'Save 35% in average within 2 minutes with AI-assistant',*/
          link: 'platform',
        },
        {
          title: 'Actionable Notifications',
          /*description: 'Save 35% in average within 2 minutes with AI-assistant',*/
          link: 'platform',
        },
        {
          title: 'Cost Segments and Budget',
          /*description: 'Save 35% in average within 2 minutes with AI-assistant',*/
          link: 'platform',
        },
        {
          title: 'Anomaly Detector',
          /*description: 'Save 35% in average within 2 minutes with AI-assistant',*/
          link: 'platform',
        }
      ]
    },

    resources: {
      items: [
        {
          title: 'Blogs',
          /*description: 'Save 35% in average within 2 minutes with AI-assistant',*/
          link: 'http://blog.reservops.ai/',
          external: true
        },
        {
          title: 'Case Studies',
          /*description: 'Save 35% in average within 2 minutes with AI-assistant',*/
          link: 'http://blog.reservops.ai/tag/case-studies/',
          external: true
        },
        {
          title: 'FAQ',
          /*description: 'Save 35% in average within 2 minutes with AI-assistant',*/
          link: 'FAQ',
          external: false
        },
        {
          title: 'About Us',
          /*description: 'Save 35% in average within 2 minutes with AI-assistant',*/
          link: 'About',
          external: false
        }
      ]
    }
  };

  return (
    <div className="row navbar-fixed m-0 p-0">
      <div className="col-12 p-0">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div 
        className="container" 
        style={{
          minHeight: '50px'
        }}>
        <div className="col-6 col-lg-3">
        <Link to='/#home'>
          <a href=""
              ><img
                width="100%"
                className="navbar-logo"
                alt="logo"
                src={logo}
              />
            </a>
          </Link>
          </div>
          <div className="col col-sm-0">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#site-nav"
            aria-controls="site-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          </div>

          <div style={{background: 'white'}} className="col col-lg-9 col-md-12 collapse navbar-collapse" id="site-nav">
            <ul className="navbar-nav text-sm-left ml-auto">
            <li className="nav-item dropdown">
                <a className="nav-link" href="#" data-toggle="dropdown"
                  >Why ReservOps.ai?
                  {/* <span className="pe-2x pe-7s-angle-down"></span> */}
                </a>
                <div className="dropdown-menu">
                  {dropdownContent.whyReservops.items.map(menuItem => (
                    <HeaderNavDropdownItem 
                      title={menuItem.title} 
                      description={menuItem.description}
                      link={menuItem.link} 
                    />
                  ))}
                </div>
              </li>
              <li className="nav-item">
              {props.hasFeaturesSection 
                ? <a className="nav-link" href="#how-it-works">How It Works?</a>
                : <Link to='/#how-it-works'><a className="nav-link" href="">How It Works?</a></Link>
              }
              </li>
              <li className="nav-item">
              {props.hasFeaturesSection 
                ? <a className="nav-link" href="#key-features">Platform</a>
                : <Link to='/#key-features'><a className="nav-link" href="">Platform</a></Link>
              }
              </li>
              <li className="nav-item">
              {props.hasFeaturesSection 
                ? <a className="nav-link" href="#brands">Customers</a>
                : <Link to='/#brands'><a className="nav-link" href="">Customers</a></Link>
              }
              </li>
              <li className="nav-item">
              {props.hasPricingSection
                ? <a className="nav-link" href="#pricing">Pricing</a>
                : <Link to='/#pricing'><a className="nav-link" href="">Pricing</a></Link>
              }
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link" href="#" data-toggle="dropdown"
                  >Resources
                  {/* <span className="pe-2x pe-7s-angle-down"></span> */}
                </a>
                  <HeaderNavDropdown content={dropdownContent.resources} />
              </li>
             {/* <li className="nav-item">
                <a className="nav-link" target='blank' href="https://blog.maxspotter.com/">Blog</a>
              </li>*/}
                <li className="nav-item text-center">
                  <a
                    href={`${process.env.REACT_APP_DASHBOARD_LINK}/register` || ''}
                    className="btn align-middle btn-primary my-2 my-lg-0"
                    >Sign Up</a
                  >
                </li>
              <li className="nav-item text-center">
                <a
                  href={process.env.REACT_APP_DASHBOARD_LINK}
                  className="btn align-middle btn-outline-primary-maxspotter my-2 my-lg-0"
                  >Login</a
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
      </div>
    </div>
  );
}

export default HeaderNav;
