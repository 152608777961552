import React from 'react';

const HeaderSection = (props) => {
  if(props.sectionHeading)
  return (
    <section style={{marginTop: '14vh', paddingTop: '2rem',boxShadow: 'rgba(224, 219, 219, 0.75) 3px 3px 1px 0px', minHeight: '13vh'  }} className="jumbotron sub-page text-center d-flex align-items-center">
        <div className="container">
        {props.sectionHeading &&
          <h1 className="display-5">{props.sectionHeading}</h1>
        }          
          {props.sectionBodyHeading && 
          <div>
            <hr />
            <h3 class="display-6">
              {`${props.sectionBodyHeading}`}
            </h3>
            {props.sectionBodyText && <p>{`${props.sectionBodyText}`}</p>}
          </div>
          }
          {!props.sectionBodyHeading &&
          <div>
            {props.sectionBodyText && <p>{`${props.sectionBodyText}`}</p>}
          </div>
          }
        </div>
        </section>
  );
  return(<div></div>);
}

export default HeaderSection;