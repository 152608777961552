/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import HeaderNavDropdownItem from './HeaderNavDropdownItem';

const HeaderNavDropdown = (props) => {
  return (<div className="row container dropdown-menu">
    {props.content.items.map(menuItem => (
                    <HeaderNavDropdownItem
                      title={menuItem.title} 
                      description={menuItem.description}
                      link={menuItem.link}
                      external={menuItem.external}
                    />
                  ))}
  </div>);
}

export default HeaderNavDropdown;