/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WhyBusinessComponents2 from '../components/WhyBusinessComponents2';
import WhyBusinessComponents3 from '../components/WhyBusinessComponents3';
import WhyBusinessComponents4 from '../components/WhyBusinessComponents4';
import WhyBusinessComponents5 from '../components/WhyBusinessComponents5';
import WhyBusinessComponents6 from '../components/WhyBusinessComponents6';
import ContentBand from '../components/ContentBand';



// const data = {
//   features:[{
//       //logo: istegelsinLogo,
//       title: "Groups",
//       explaination: "Create groups based around the organization of your company and send relevant team members information on budgets, forecasts, and costs."
//     },
//     {
//       //logo: istegelsinLogo,
//       title: "Reservation Inventory",
//       explaination: "View all reservations for all services in one place. See which reservations are expiring and how each reservation has been utilized to understand your purchase."
//     },
//     {
//       //logo: istegelsinLogo,
//       title: "Discount Attribution",
//       explaination: "See where discounts and upfront capital are being used to optimize tax write-offs and amortization."
//     }
//   ]
// };


function WhyEngineeringTeams() {
  return (
    <div>
    {/* <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NV4MML7"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> */}
    <Header sectionHeading='Solutions for Engineering Teams' />
    <div class="p-4 text-center">
        <div className="container d-flex justify-content-center">
          <div className="col-12 col-md-8 text-center pb-5">
            <p>We save engineering teams hundreds of hours through our end-to-end, automation first approach that simplifies tasks like cost attribution, executing exchanges, re-sale, renewals, and reservation purchases.
            </p>
              <a
                className="btn btn-xl btn-primary"
                href={`${process.env.REACT_APP_DASHBOARD_LINK}/register` || ''}
                id="calendly-trigger"
              >
              Get Started For Free
              </a>
          </div>
        </div>
    </div>


    <hr></hr>   
    <div class="p-4 text-center">
        <div className="container d-flex justify-content-center">
        <div className="col-12 col-md-8 text-center pb-5">
        <h2><b>Partner with ReservOps.ai with a 10-minute install.</b></h2>
        <br></br>
        <p>
        Find out how much you can save and exactly where your money is going using a Cloud Formation Template, Terraform Template, or manual addition of the read-only IAM role.
        </p>
        <div className="col-12 p-0 m-0">
        <WhyBusinessComponents3 disabledCard={0}></WhyBusinessComponents3>
        </div>
          <a
            className="btn btn-xl btn-primary"
            href={`${process.env.REACT_APP_DASHBOARD_LINK}/register` || ''}
            id="calendly-trigger"
          >
          Try For Free
          </a>
        </div>
        </div>
    </div>
    <hr></hr> 
    <div class="p-4 text-center">
        <div className="container d-flex justify-content-center">
        <div className="col-12 col-md-8 text-center pb-5">
        <h2><b>Automated and accurate cost attribution.</b></h2>
        <br></br>
        <p>
        We save engineering teams hundred of hours in attributing costs to products, services, and teams. Using AI and automated tag management, ReservOps.ai segments your infrastructure and aligns cloud costs with your organizational structure.
        </p>
        </div>
        </div>
        <div className="col-12 p-0 m-0">
            <WhyBusinessComponents4 disabledCard={0}></WhyBusinessComponents4>
        </div>
    </div>
    <hr></hr> 
    <div class="p-4 text-center">
        <div className="container d-flex justify-content-center">
        <div className="col-12 col-md-8 text-center pb-5">
          <h2><b>Stop spending time monitoring costs.</b></h2>
          <br></br>
          <p>
          ReservOps.ai monitors your costs on an hourly basis to identify anomalous spending and savings opportunities as they occur. Send integrated alerts via email or Slack to groups in your organization to never again see another on-demand spike.
          </p>
        </div>
        </div>
        <div className="col-12 p-0 m-0">
          <WhyBusinessComponents5 disabledCard={0}></WhyBusinessComponents5>
        </div>
    </div>
    <hr></hr>
    <div class="p-4 text-center">
        <div className="container d-flex justify-content-center">
        <div className="col-12 col-md-8 text-center pb-5">
          <h2><b>No more spreadsheets.</b></h2>
          <h2><b> We’ll find the optimal reservation purchase for you.</b></h2>
          <br></br>
          <p>
          Identifying the optimal purchasing strategy requires hundreds to trillions of potential approaches. We do the work for you and let you compare different purchasing recommendations instead of spending countless hours finding the right one.
          </p>
        </div>
        </div>
        <div className="col-12 p-0 m-0">
          <WhyBusinessComponents6 disabledCard={0}></WhyBusinessComponents6>
        </div>
    </div>
    <hr></hr> 
    <div class="p-4 text-center">
        <div className="container d-flex justify-content-center">
        <div className="col-12 col-md-8 text-center pb-5">
          <h2><b>Don't waste time in Cost Explorer.</b></h2>
          <h2><b>We've got it covered reservations for you.</b></h2>
          <br></br>
          <p>
          Finding opportunities for savings unless the necessary purchasing, exchanging, and renewing actions are executed. ReservOps.ai allows you to automate these processes to ensure that you never leave money on the table.
          </p>
        </div>
        </div>
        <div className="col-12 p-0 m-0">
          <WhyBusinessComponents2 disabledCard={0}></WhyBusinessComponents2>
        </div>
    </div>
    <hr></hr> 
    <ContentBand  sectionHeading='Start with our free dashboard' />
    <div class="p-4 text-center">
        <p>
        Get a risk-free analysis of your potential savings opportunities in less than 10 minutes by installing our free dashboard using minimally permissive, read-only IAM credentials.
        </p>
          <a
            className="btn btn-xl btn-primary"
            href={`${process.env.REACT_APP_DASHBOARD_LINK}/register` || ''}
            id="calendly-trigger"
          >
          Get Started For Free
          </a>
    </div>
    <Footer></Footer>
    </div>
  )
}

export default WhyEngineeringTeams;
