/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import StatsSection from '../components/StatsSection';
import awsLogo from '../images/aws_logo.png';
import azureLogo from '../images/azure_icon.svg';
import gcpLogo from '../images/gcp_icon.png';
import alibabaLogo from '../images/alibaba_icon.png';
import awsEc2 from '../images/aws_ec2.png';
import awsEbs from '../images/aws_ebs.png';
import awsEcs from '../images/aws_ecs.png';
import { Link } from 'react-router-dom';
import ComingSonnLoader from "react-spinners/ClockLoader";

const AllUsers = (props) => {

  return (
    <div>
      <Header sectionHeading='Websites' />
      <section className="section sub-page text-center d-flex align-items-center">
      <div className="container entry-content">
        <h4 className="mb-4">
          Maximize Spot Instances Usage for Your Workloads
        </h4>
        <p>
          Spot instances are cheap but they can be terminated by AWS with 2 minutes notice. 
          However, MaxSpotter prediction algorithms continously measures and predicts termination likelihood. 
          Before the termination notice by AWS, it auto replaces existing spot instances with new ones for service continuity 
        </p>
      </div>
    </section>
    <section className="jumbotron sub-page text-center d-flex align-items-center">
      <div className="container entry-content">
        <h4 className="mb-4">
          Convert Your Existing Servers to Spot Instances Immediately
        </h4>
        <p>
          You don't need to worry about your existing servers. MaxSpotter supports opt-in or opt-out mode. 
          It can convert your existing servers in AutoScaling groups within minutes gracefully.
        </p>
      </div>
    </section>
    <div className="section sub-page text-center d-flex align-items-center">
      <div className="container">
        <h4 className="mb-4">
          Visualize Your Spot Usage and Cost Savings Insights
        </h4>
        <p>
          MaxSpotter Dashboar provides detailed statistics about your spot instances, potential, actual costs and savings
        </p>
      </div>
    </div>
    <div className="row container col-12 d-flex justify-content-center align-items-center p-3 my-3">
      <div className="row container d-flex justify-content-center align-items-center mb-3">
        <div className="col-9">
        <div className="row d-flex justify-content-center align-items-center my-2">
          <div className="col-3 d-none d-md-block p-0 m-0">
              <img 
                width="100%"
                src={awsLogo}
                alt="aws"
              />
            </div>
            <div className="col-12 col-md-9">
            <h2>Amazon Web Services</h2>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-9">
          <h5>
            <img src={awsEc2} alt="ec2" style={{maxHeight: '30px'}}/>
             <span className='ml-3'>Amazon EC2</span>
          </h5>
          <p>
            vel cursus ultrices. Curabitur vel turpis 
            ac nisl dictum sodales. Pellentesque.
          </p>
          <h5>
            <img src={awsEbs} alt="ec2" style={{height: '30px'}}/>
             <span className='ml-3'>Amazon Elastic Bean Stalk</span>
          </h5>
          <p> 
            adipiscing elit. Vivamus ultricies dolor 
            vel cursus ultrices. Curabitur vel turpis 
            ac nisl dictum sodales. Pellentesque.
          </p>
          <h5>
            <img src={awsEcs} alt="ec2" style={{maxHeight: '30px'}}/>
             <span className='ml-3'>Amazon ECS</span>
          </h5>
          <p>
            Lorem ipsum dolor sit amet, consectetur 
            adipiscing elit. Vivamus ultricies dolor 
            vel cursus ultrices. Curabitur vel turpis 
            ac nisl dictum sodales. Pellentesque.Lorem 
            ipsum dolor sit amet, consectetur 
            adipiscing elit. Vivamus ultricies dolor 
            vel cursus ultrices. Curabitur vel turpis 
            ac nisl dictum sodales. Pellentesque.
          </p>
        </div>
      </div>
      <div className="row container d-flex justify-content-center align-items-center mb-3">
        <div className="col-9">
        <div className="row d-flex justify-content-center align-items-center my-2">
            <div className="col-3 d-none d-md-flex mb-2">
              <img 
                width="80%"
                src={azureLogo}
                alt="azure"
              />
            </div>
            <div className="col-12 col-md-9">
            <h2>Microsoft Azure</h2>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-9">
          <div className="row  col-12 py-5 px-4">
            <div className="col-2 d-none d-md-flex justify-content-center align-items-center">
              <ComingSonnLoader loading={true} />
            </div>
            <div className="col-12 col-md-10 d-flex justify-content-center align-items-center">
              <div className="col-12">
                <h5>Coming Soon</h5>
                <p className="m-0">
                Stay tuned... <Link to="/contact"><a href=''>Contact Us</a></Link>
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row container d-flex justify-content-center align-items-center mb-3">
        <div className="col-9">
        <div className="row d-flex justify-content-center align-items-center my-2">
          <div className="col-3 d-none d-md-flex">
              <img 
                width="80%"
                src={gcpLogo}
                alt="gcp"
              />
            </div>
            <div className="col-12 col-md-9">
            <h2>Google Cloud Platform</h2>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-9">
          <div className="row  col-12 py-5 px-4">
            <div className="col-2 d-none d-md-flex justify-content-center align-items-center">
              <ComingSonnLoader loading={true} />
            </div>
            <div className="col-12 col-md-10 d-flex justify-content-center align-items-center">
              <div className="col-12">
                <h5>Coming Soon</h5>
                <p className="m-0">
                <Link to="/contact"><a href=''>Subscribe</a></Link> to get updates.
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row container d-flex justify-content-center align-items-center mb-3">
        <div className="col-9">
        <div className="row d-flex align-items-center my-2">
            <div className="col-3 d-none d-md-flex">
              <img 
                width="80%"
                src={alibabaLogo}
                alt="alibaba"
              />
            </div>
            <div className="col-12 col-md-9">
            <h2>Alibaba Cloud</h2>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-9">
          <div className="row  col-12 py-5 px-4">
            <div className="col-2 d-none d-md-flex justify-content-center align-items-center">
              <ComingSonnLoader loading={true} />
            </div>
            <div className="col-12 col-md-10 d-flex justify-content-center align-items-center">
              <div className="col-12">
                <h5>Coming Soon</h5>
                <p className="m-0">
                Stay tuned... <Link to="/contact"><a href=''>Contact Us</a></Link>
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <StatsSection headerText={'Who else should use MaxSpotter?'} disabledCard={4}></StatsSection>
    <Footer></Footer>
    </div>
  );
}

export default AllUsers;