/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const WhyBusinessComponents3 = (props) => {

  const renderRegularCards = (
      <div className="row d-flex justify-content-center col-12 p-0 m-0">
          <div className="col-6">
            <div className="card pricing h-100">
                <h4 className="mb-4 mt-4"><b>What our IAM role does:</b></h4>
                <span>Ingests cost, utilization, and billing data</span>
            </div>
          </div>
          <div  className="col-6">
          <div className="card pricing h-100">
                <h4 className="mb-4 mt-4"><b>What our IAM role doesn’t do:</b></h4>
                <span>Impact anything in your environment or view any sensitive workload data.</span>
           </div>
          </div>
        </div>
  );

  return (
    <div id="who-is-it-for" className="section bg-light">
    <div className="container">
      <div className="row">
        {props.headerText && <div style={{color: 'white'}} className="col-12 section-title text-center mb-5">
          <h3>{props.headerText}</h3>
          <p>
          </p>
        </div>}
        <div>
        {props.disabledCard > 0 ? renderRegularCards  : renderRegularCards}
        </div>
      </div>
    </div>
  </div>
  ); 
}

export default WhyBusinessComponents3;
