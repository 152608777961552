import React, {useState} from 'react';
import awsLogo from '../images/aws_logo.png';
import azureLogo from '../images/azure-logo.png';
import gcpLogo from '../images/gcp-logo.png';
import alibabaLogo from '../images/alibaba-logo.png';

const styles = {
  platformLogo: {
    maxWidth: '100%',
    height: 'auto',
    opacity: '0.3',
    cursor: 'pointer'
  },
  platformLogoActive: {
    maxWidth: '100%',
    height: 'auto',
    cursor: 'pointer',
    // border: '1px solid #194b88'
  },
  platformOther: {
    maxWidth: '100%',
    height: '100%',
    opacity: '0.3',
    cursor: 'pointer',
    fontSize: '1.25em',
    lineHeight: '100%',
    margin: '0',
    textAlign: 'center',
    // color: '#194b88',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Product Sans, helvetica',
    marginTop: '-0.25em'
  },
  platformOtherActive: {
    maxWidth: '100%',
    height: '100%',
    cursor: 'pointer',
    fontSize: '1.25em',
    lineHeight: '100%',
    margin: '0',
    textAlign: 'center',
    // color: '#4CA5DC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontFamily: 'Product Sans, helvetica',
    marginTop: '-0.25em'
  },
  platformLogoHeader: {
    maxWidth: '100%',
    height: 'auto',
    opacity: '0.4'
  },
  platformHeaderLabel: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '12px',
    marginTop: '0.5em'
  },
  platformHeaderLabelText: {
    fontSize: '6px'
  },
  platformHeaderIcon: {
    maxWidth: '5rem',
  },
  otherPlatformFormField: {
    width: '100%',
  },
};


export const PlatformSelector = (props) => {
  const [otherPlatform, setOtherPlatform] = useState('');
  
  const handlePlatformChange = (key) => (event) => {
    props.setPlatform(key);
  }

  const handleOtherPlatformChange = (event) => {
    if (props.setOtherPlatform) {
      props.setOtherPlatform(event.target.value);
    }
    setOtherPlatform(event.target.value);
  };
  if (props.forHeaderSection) {
    return (<div className="row no-padding no-margin">
    <div className="col-lg-3"></div>
    <div className="row col-lg-6">
    <div className="col-lg-3 col-md-3 col-3 p-lg-4 p-sm-3 mt-2">
      <img 
        style={{...styles.platformLogoActive, cursor: 'unset'}}
        src={awsLogo}  
        alt='aws'
        // width='60%'
      />
    </div>
    <div className="col-lg-3 col-md-3 col-3 p-lg-4 p-md-5 p-sm-3">
      <img 
        style={styles.platformLogoHeader}
        src={azureLogo}
        alt='azure'
      />
      <div style={styles.platformHeaderLabel}>
        <p style={styles.platformHeaderLabelText} className="btn btn-sm btn-outline-primary">Coming Soon</p>
      </div>
    </div>
    <div className="col-lg-3 col-md-3 col-3 p-lg-4 p-md-5 p-sm-3">
      <img 
        style={styles.platformLogoHeader}
        src={gcpLogo}
        alt='gcp'
      />
      <div style={styles.platformHeaderLabel}>
        <p style={styles.platformHeaderLabelText} className="btn btn-sm btn-outline-primary">Coming Soon</p>
      </div>
    </div>
    <div className="col-lg-3 col-md-3 col-3 p-lg-4 p-md-5 p-sm-3">
      <img 
        style={styles.platformLogoHeader}
        src={alibabaLogo}
        alt='alibaba'
      />
      <div style={styles.platformHeaderLabel}>
        <p style={styles.platformHeaderLabelText} className="btn btn-sm btn-outline-primary">Coming Soon</p>
      </div>
    </div>
    </div>
    <div className="col-lg-3"></div>
  </div>);
  } else if (props.forBeta) {
    return (<div className="row no-padding no-margin">
    <div className="row col-lg-12">
    <div className="col-6 col-md-1-5 col-lg-1-5 p-3">
      <img 
        style={props.platform === 'AWS' ? styles.platformLogoActive : styles.platformLogo}
        src={awsLogo}  
        alt='aws'
        onClick={handlePlatformChange('AWS')}
      />
    </div>
    <div className="col-6 col-md-1-5 col-lg-1-5 p-3">
      <img 
        style={props.platform === 'Azure' ? styles.platformLogoActive : styles.platformLogo}
        src={azureLogo}
        alt='azure'
        onClick={handlePlatformChange('Azure')}
      />
    </div>
    <div className="col-6 col-md-1-5 col-lg-1-5 p-3">
      <img 
        style={props.platform === 'GCP' ? styles.platformLogoActive : styles.platformLogo}
        src={gcpLogo}
        alt='gcp'
        onClick={handlePlatformChange('GCP')}
        />
    </div>
    <div className="col-6 col-md-1-5 col-lg-1-5 p-3">
      <img 
        style={props.platform === 'Alibaba' ? styles.platformLogoActive : styles.platformLogo}
        src={alibabaLogo}
        alt='alibaba'
        onClick={handlePlatformChange('Alibaba')}
      />
    </div>
    <div className="col-6 col-md-1-5 col-lg-1-5 p-3">
      <p
        style={props.platform === 'Other Platform' ? {...styles.platformOtherActive, marginTop:  0} : {...styles.platformOther, marginTop: 0}}
        onClick={handlePlatformChange('Other Platform')}
        >Other</p>
    </div>
    </div>
    {props.platform === 'Other Platform' && <div className="row col-12">
    <form className="form-group w-100 mb-0">
      <div className="col-12 mb-sm-1 mt-3 mb-0">
        <input 
          style={styles.otherPlatformFormField} 
          className="form-group form-control formField" 
          type="text" 
          placeholder="Other Platform" 
          value={otherPlatform} 
          onChange={handleOtherPlatformChange} 
        />
      </div>
    </form>
    </div>}
  </div>);
  } else {
    return (<div className="row no-padding no-margin">
    <div className="col-lg-1"></div>
    <div className="row col-lg-10">
    <div className="col-lg-1-5 col-md-1-5 col-6 p-2 mt-2">
      <img 
        style={props.platform === 'AWS' ? styles.platformLogoActive : styles.platformLogo}
        src={awsLogo}  
        alt='aws'
        onClick={handlePlatformChange('AWS')}
      />
    </div>
    <div className="col-lg-1-5 col-md-1-5 col-6 p-2 mt-2">
      <img 
        style={props.platform === 'Azure' ? styles.platformLogoActive : styles.platformLogo}
        src={azureLogo}
        alt='azure'
        onClick={handlePlatformChange('Azure')}
      />
    </div>
    <div className="col-lg-1-5 col-md-1-5 col-6 p-3 mt-2">
      <img 
        style={props.platform === 'GCP' ? styles.platformLogoActive : styles.platformLogo}
        src={gcpLogo}
        alt='gcp'
        onClick={handlePlatformChange('GCP')}
        />
    </div>
    <div className="col-lg-1-5 col-md-1-5 col-6 p-2 mt-2">
      <img 
        style={props.platform === 'Alibaba' ? styles.platformLogoActive : styles.platformLogo}
        src={alibabaLogo}
        alt='alibaba'
        onClick={handlePlatformChange('Alibaba')}
      />
    </div>
    <div className="col-lg-1-5 col-md-1-5 col-12 mt-3">
      <p
        style={props.platform === 'Other Platform' ? styles.platformOtherActive : styles.platformOther}
        onClick={handlePlatformChange('Other Platform')}
        >Other</p>
    </div>
    </div>
    <div className="col-lg-1"></div>
  </div>);
  }
};

export default PlatformSelector;