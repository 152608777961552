import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const HowItWorks = (props) => {
  return (
    <div>
      <Header sectionHeading='How it Works?' />
      <div className="align-items-center my-lg text-center">
      <div className="container">
        <h4 className="mb-4">
          Get Started with Easy Installation
        </h4>
        <div className="container entry-content text-left">
          <p>
            MaxSpotter offers two minutes installation with Cloud Formation or
            Terraform stack. That’s it! You can start benefiting from cheap Spot
            instances. Check out our documentation for detailed instructions on
            how to integrate with your platform on AWS.
          </p>
          <p>
            Simply login to our customer dashboard with your customer account,
            and you can get started using MaxSpotter Analytics immediately.
          </p>
          <p>
            Once MaxSpotter manages your servers, MaxSpotter will begin scanning
            your instances and uses spot instances efficiently. Check your
            dashboard to measure number of spot instances, actual costs, savings
            and spot requests history. From there, it’s all up to you how you’ll
            use MaxSpotter! A good place to begin is by scanning the dashboard
            overview or setting goals to track. Thanks to easy integration and
            one-click integration, you can begin minimizing your server costs
            without a hassle. Get started now by <a href="https://maxspotter.xyz/register">signing up</a>.
          </p>
        </div>
      </div>
    </div>
      <Footer></Footer>
    </div>
  );
}

export default HowItWorks;