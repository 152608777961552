import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';
import onePager from '../images/main_one_pager.png';


const styles = {
  platformLogoActive: {
    maxWidth: '100%',
    height: 'auto',
    cursor: 'pointer',
  }
};

const PlatformComponent = (props) => {  
  return (
    <div className='container-fluid no-padding no-margin'>
      <img 
        style={{...styles.platformLogoActive, cursor: 'unset'}}
        src={onePager}  
        alt='Reservops.ai SaaS Platform'
        //width='1000%'
      />
    </div>
  );
}

export default PlatformComponent;