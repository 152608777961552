import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Agencies = (props) => {
  return (
    <div>
      <Header sectionHeading='Saas Platforms'/>
      <div className="sub-page section text-center d-flex align-items-center">
      <div className="container entry-content">
        <h4 className="mb-4">
          Maximize Spot Instances Usage for Your Workloads
        </h4>
        <p>
          Spot instances are cheap but they can be terminated by AWS with 2 minutes notice. 
          MaxSpotter continously measures and predicts termination likelihood. Before the termination notice by AWS, 
          it auto replaces existing spot instances with new ones for service continuity 
        </p>
      </div>
    </div>
    <section className="jumbotron sub-page text-center d-flex align-items-center">
      <div className="container entry-content">
        <h4 className="mb-4">
          Convert Your Existing Servers to Spot Instances Immediately
        </h4>
        <p>
          You don't need to worry about your existing servers. MaxSpotter supports opt-in or opt-out mode. 
          It can convert your existing servers in AutoScaling groups within minutes gracefully.
        </p>
      </div>
    </section>
    <div className="sub-page section text-center d-flex align-items-center">
      <div className="container">
        <h4 className="mb-4">
          Visualize Your Spot Usage and Cost Savings Insights
        </h4>
        <p>
          MaxSpotter Dashboar provides detailed statistics about your spot instances, potential, actual costs and savings
        </p>
      </div>
    </div>
    <Footer></Footer>
    </div>
  );
}

export default Agencies;