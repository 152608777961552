import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Careers = (props) => {
  return (
    <div>
      <Header sectionHeading='Join MaxSpotter' />
      <div className="align-items-center my-lg text-center">
      <div className="container">
        <h3 className="mb-4">
          Work At MaxSpotter
        </h3>
        <div className="container entry-content col-sm-10">
          <p>
            At MaxSpotter, we’re committed to empowering cloud solutions, SaaS,
            PaaS platforms, e-commerce sites, any workload on cloud. Together,
            we can work to improve advanced predictive algorithms to manage
            cloud infrastructures.
          </p>
          <p>
            If you would like to join our energetic team and work at MaxSpotter,
            drop us a line by emailing <a href="mailto:max@maxspotter.com">max@maxspotter.com</a> We’d love to get in
            touch with you!
          </p>
        </div>
      </div>
    </div>
      <Footer></Footer>
    </div>
  );
}

export default Careers;