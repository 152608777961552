import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useLocation, useHistory } from 'react-router-dom';
import { sendData } from '../service/service';
import ConsentCheckboxes from '../components/ConsentCheckboxes.';

const styles = {
  formField: {
    width: '100%',
    height: 'auto',
  },
  headingStyle: {
    textAlign: 'center',
    paddingTop: '2em',
    marginBottom: '2em',
  }
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Contact = (props) => {
  const query = useQuery();
  const history = useHistory();
  const platform = (query.get('platform'));
  const instanceCount = (query.get('instanceCount'));
  const billing = (query.get('billing'));
  const origin = query.get('origin');
  const [userPlatform, setUserPlatform] = useState(platform || '');
  const [userInstanceCount, setUserInstanceCount] = useState(instanceCount || '');
  const [userBilling, setUserBilling] = useState(billing || '');
  const [userMessage, setUserMessage] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userCompany, setUserCompany] = useState('');
  const [userJobTitle, setUserJobTitle] = useState('');
  const [errorLimit, setErrorLimit] = useState(0);
  const [ consentChecked, setConsentChecked ] = useState(false);
  const [ communicationsChecked, setCommunicationsChecked ] = useState(true);

  const handleOTextFieldChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleConsentChecked = (value) => {
    setConsentChecked(value);
  }

  const handleCommunicationsChecked = (value) => {
    setCommunicationsChecked(value);
  }

  const handleSubmit = (event) => {
    event.preventDefault(true);
    if (consentChecked) {
      sendData({
        userPlatform,
        userInstanceCount,
        userBilling,
        userEmail,
        userFirstName,
        userLastName,
        userCompany,
        userJobTitle,
        userMessage,
        tag: origin ? `contact:${origin}` : 'contact',
        processConsent: consentChecked,
        communicationsConsent: communicationsChecked,
      }).then((res) => {
        if (res.data && res.data.redirection && res.data.redirectTo) {
          window.location.replace(res.data.redirectTo);
        } else if (res.data && res.data.redirection) {
          window.location.replace(`${process.env.REACT_APP_DASHBOARD_LINK}/login`);
        } else {
          history.push('/welcome');
        }
      }).catch((err) => {
        console.log(err);
        if (errorLimit > 0) {
          setErrorLimit(errorLimit - 1);
          alert('An error occured. Please try again.');
        } else {
          history.push('/welcome');
        }
      });
    } else {
      alert('We need your consent to continue.');
    }
  };

  return (
    <div>
    <Header disableHeaderSection={true}></Header>
    <div className="section mt-5">
      <div className="container">
        <div className="row justify-content-md-center">
        <h4 style={styles.headingStyle}>{`We need some info and will get in touch soon.`} </h4>
          <div className="col col-md-9">
          <form onSubmit={handleSubmit} className="form-group w-100 row">
            <div className="col-12 col-md-6 mb-sm-1 mt-sm-1">
              <input 
                style={styles.formField} 
                className="form-group form-control formField" 
                type="text" 
                placeholder="First Name*" 
                value={userFirstName} 
                onChange={handleOTextFieldChange(setUserFirstName)}
                required
              />
            </div>
            <div className="col-12 col-md-6 mb-sm-1 mt-sm-1">
              <input 
                style={styles.formField} 
                className="form-group form-control formField" 
                type="text" 
                placeholder="Last Name*" 
                value={userLastName} 
                onChange={handleOTextFieldChange(setUserLastName)}
                required
              />
            </div>
            <div className="col-12 col-md-6 mb-sm-1 mt-sm-1">
              <input 
                style={styles.formField}
                className="form-group form-control formField" 
                type="email"
                placeholder="Email*"
                value={userEmail} 
                onChange={handleOTextFieldChange(setUserEmail)}
                required
              />
            </div>
            <div className="col-12 col-md-6 mb-sm-1 mt-sm-1">
              <input 
                style={styles.formField} 
                className="form-group form-control formField" 
                type="text" 
                placeholder="Company Name"
                value={userCompany} 
                onChange={handleOTextFieldChange(setUserCompany)}
              />
            </div>
            <div className="col-12 col-md-6 mb-sm-1 mt-sm-1">
              <input 
                style={styles.formField} 
                className="form-group form-control formField" 
                type="text" 
                placeholder="Job Title"
                value={userJobTitle} 
                onChange={handleOTextFieldChange(setUserJobTitle)}
              />
            </div>
            <div className="col-12 col-md-6 mb-sm-1 mt-sm-1">
              <input 
                style={styles.formField} 
                className="form-group form-control formField" 
                type="number" 
                placeholder="Instance Count"
                value={userInstanceCount}
                onChange={handleOTextFieldChange(setUserInstanceCount)}
              />
            </div>
            <div className="col-12 col-md-6 mb-sm-1 mt-sm-1">
              <input 
                style={styles.formField} 
                className="form-group form-control formField" 
                type="number" 
                placeholder="Monthly Billing ($)"
                value={userBilling}
                onChange={handleOTextFieldChange(setUserBilling)}
              />
            </div>
            <div className="col-12 col-md-6 mb-sm-1 mt-sm-1">
              <input
                style={styles.formField} 
                className="form-group form-control formField" 
                type="text" 
                placeholder="Cloud Provider"
                value={userPlatform}
                onChange={handleOTextFieldChange(setUserPlatform)}
              />
            </div>
            <div className="col-12 mb-sm-1 mt-sm-1">
              <textarea 
                style={styles.formField} 
                className="form-group form-control formField" 
                type="text" 
                placeholder="Message..."
                value={userMessage}
                onChange={handleOTextFieldChange(setUserMessage)}
                rows='5'
              />
            </div>
            <ConsentCheckboxes defaultConsent={false} defaultCommunications={true} setCommunicationsChecked={handleCommunicationsChecked} setConsentChecked={handleConsentChecked} />
            <div className="col-12">
              <button disabled={!consentChecked} type="submit" class="btn btn-primary mb-2">Submit</button>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    </div>
  );
}

export default Contact;