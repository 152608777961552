import axios from 'axios';
import * as bcrypt from 'bcryptjs';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const auth = () => {
  const audience = 'landing';
  return new Promise((resolve, reject) => {
    const salt = bcrypt.genSaltSync(11);
    const hash = bcrypt.hashSync(audience, salt);
    const data = {
      audience,
      apiKey: hash
    };
    try {
      const result = axios.post(`${backendUrl}auth/service`, data);
      return resolve(result);
    } catch (err) {
      return reject(err);
    }
  });
};

export const postRequest = async (url, data, config) => {
  let token = '';
  return auth().then(async (res) => {
      if (res.data && res.data.data && res.data.data.token) {
        token = res.data.data.token;
        if (res.data && res.data.data && res.data.data.refreshToken) {
          if (token.length > 0) {
            const defaultConfig = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
              return axios.post(url, data, { ...defaultConfig, ...config });
          }
        }
      }
  });
}

export const sendData = (data) => {
  return postRequest(`${backendUrl}landing/sendUserData`, data);
}

export default sendData;
