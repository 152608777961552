/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AboutComponent from '../components/About';
import uaProfile from "../images/ua-profile.png";
import doProfile from "../images/do-profile.png";
import ssProfile from "../images/ss-profile.png";
import jkProfile from "../images/john-k-profile.jpg";

const data = {
  members: [
    {
      name: 'Ugur A.',
      image: uaProfile,
      bio: "Designs best autonomous experience with maximum cost saving for you",
      shorttitle: "Cloud Autonomous Officer",
      link: "https://twitter.com/uguracar"
    },
    {
      name: 'Doruk O.',
      image: doProfile,
      bio: "Questions how to make you happy?",
      shorttitle: "Customer Happiness Officer",
      link: "https://twitter.com/dorukozdemir"
    },
    {
      name: 'Sarp S.',
      image: ssProfile,
      bio: "Thinks how to utilize your AWS cloud the most cost efficiently?",
      shorttitle: "DevOps Guru",
      link: "https://twitter.com/sarpsaatci"
    },
    {
      name: 'John K.',
      image: jkProfile,
      bio: "Assists throughout your AWS cloud cost optimization journey",
      shorttitle: "Personal AI Assistant",
      link: "https://twitter.com/reservopsai"
    }
]};


function About() {
  return (
    <div>
    {/* <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NV4MML7"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> */}
    <Header disableHeaderSection='true' />
    <div className="align-items-center my-lg text-center">
      <div className="container">
        <h3 className="mb-4">
          Reservops.ai has a distributed team across EU and USA.
        </h3>
        <div className="container entry-content">
          <p>
            The team has leaded the biggest PaaS providers cloud infrastructures 
            more than 5 years.  
          </p>
          <p>
            Struggled high costs and experienced the difficulty and lacks of automation in Cloud Providers.  
          </p>
          <p>
            Established in <b>July 2020</b>. Started their journey first reducing their own PaaS platform that is deployed in <b>US</b>, <b>Japan</b>, <b>EU</b>, <b>China</b> and <b>Australia</b> region of AWS.  
          </p>
          <p>
            Now, it's time to reduce your cost with <b>Reservops.ai</b>.   
          </p>
          <p>
            We are dedicated to serve you the best cost visibility and saving service experience without touching your infrastructure.     
          </p>
          <p>
            Within minutes, full automation with AI assistant and up to 70% cost saving.       
          </p>
        </div>
        <div className="container">
          <div className="row"> 
              {data.members.map(member => (
                    <div className="col">
                      <AboutComponent 
                        data={member}
                      />
                    </div>
                  ))}
          </div>  
        </div>
      </div>
        <a
          className="btn btn-xl btn-primary"
          href={`${process.env.REACT_APP_DASHBOARD_LINK}/register` || ''}
          id="calendly-trigger"
        >
          Get Started For Free
        </a>
    </div>
    <Footer></Footer>
    </div>
  )
}

export default About;
