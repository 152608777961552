/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

function CookiePolicy() {
  return (
    <div>
    <Header sectionHeading='Cookie Policy' />
    <div className="align-items-center my-lg text-center">
      <div className="container">
        <div className="container entry-content">
          <p>
          We may use cookies, web beacons, tags, scripts, local shared objects 
          such as HTML5 and Flash (sometimes called "flash cookies"), advertising 
          identifiers (including mobile identifiers such as Apple’s IDFA or Google’s 
          Advertising ID) and similar technology. Cookies are in connection with your use of the Service, third party websites, and mobile applications. Cookies may have unique identifiers, and reside, among other places, on your computer or mobile device, in emails we send to you, and on our web pages. Cookies may transmit information about you and your use of the Service, such as your browser type, search preferences, IP address, data relating to advertisements that have been displayed to you or that you have clicked on, and the date and time of your use. Cookies may be persistent or stored only during an individual session.
          </p>
          <h3 className="mb-4">
            The purposes for which we use Cookies
          </h3>
          <p className='text-left'>
          </p>
          <div class="row">
              <p class="col-lg-3 col-md-3 col-sm-3 col-5">
                  <b class="privacy-bold">Purpose</b>
              </p>
              <p class="col-lg-9 col-md-9 col-sm-9 col-7">
                  <b class="privacy-bold">Explanation</b>
              </p>
          </div>
          <div class="row">
              <p class="col-lg-3 col-md-3 col-sm-3 col-5 privacy-contents">
                  Processes
              </p>
              <p class="col-lg-9 col-md-9 col-sm-9 col-7 privacy-contents">
                  Intended to make the Service work in the way you expect. For example, we use a Cookie that tells us whether you have given explicit consent during submitting a form.
              </p>
          </div>
          <div class="row">
              <p class="col-lg-3 col-md-3 col-sm-3 col-5 privacy-contents">
                  Marketing
              </p>
              <p class="col-lg-9 col-md-9 col-sm-9 col-7 privacy-contents">
                  Intended to improve the traction to the Service. For example, we use a Cookie that makes A/B testing by displaying things in different ways according to relative or random variants in order to better understand your preferences in the Service.
              </p>
          </div>
          <div class="row">
              <p class="col-lg-3 col-md-3 col-sm-3 col-5 border-black">
                  Analytics
              </p>
              <p class="col-lg-9 col-md-9 col-sm-9 col-7 privacy-contents">
                  Intended to help us understand how visitors use the Service. For example, we use some Google Analytics tools.
              </p>
          </div>
          <p class="privacy-contents">
              You can set some Cookie preferences through your device or browser settings, but doing so may affect the functionality of the Service. The method for disabling Cookies may vary by device and browser, but can usually be found in your device or browser preferences or security settings. For example, iOS and Android devices each have settings which are designed to limit forms of ad tracking. 
          </p>
        </div>
      </div>
      <Link to='/contact?origin=cookie-policy'>
        <a
          className="btn btn-xl btn-primary"
          href=""
        >
          Contact Us
        </a>
      </Link>
    </div>
    <Footer></Footer>
    </div>
  )
}

export default CookiePolicy;
