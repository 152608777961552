/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import logo from '../images/reservopsai_logo.png';
import { Link } from 'react-router-dom';
import { PopupText } from 'react-calendly';

const Footer = (props) => {
  return (
    <div className="section bg-light" id="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-3">
          <Link to='/'>
          <a href=""
              >
            <img src={logo} className="footer-logo" alt="Logo" />
            </a>
            </Link>
          </div>
          <div className="col-sm-3">
            <h4>Product</h4>
            <ul className="list-unstyled footer-links ml-1">
              {/* <Link to='/what-is-maxspotter'>
                <li><a href="">What is MaxSpotter?</a></li>
              </Link>
              <Link to='/who-is-it-for'>
                <li><a href="">Who is it for?</a></li>
              </Link> */}
              <li>
                <a
                  href="#"
                >
                  <PopupText style={{color: 'black'}}
                    text="Schedule a live demo"
                    url="https://calendly.com/maxspotter/demo"
                  />
                </a>
              </li>
              <li>
                <a href={`${process.env.REACT_APP_DASHBOARD_LINK}/register` || ''}>Get Started for Free</a>
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h4>Company</h4>
            <ul className="list-unstyled footer-links ml-1">
              <Link to='/about'>
                <li><a href="">About Us</a></li>
              </Link>
            </ul>
          </div>
          <div className="col-sm-2">
            <a href="#app-top" className="btn btn-sm btn-outline-primary-maxspotter ml-1">Go to Top</a>
          </div>
        </div>
        <div className=" text-center mt-4">
          <small className="text-muted"
            >Copyright ©
            <script type="text/javascript">
              document.write(new Date().getFullYear());
            </script>
            All rights reserved by FaaS IT Solutions
          </small>
        </div>
      </div>
    </div>
  );
}

export default Footer;