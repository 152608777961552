import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Welcome = (props) => {
  return (
    <div>
      <Header sectionHeading='Thanks. You will receive an email to complete your registration very shortly'></Header>
    <div class="align-items-center my-lg text-center">
      <div class="container col-sm-9">
        <h3>
        The Worlds Fastest AWS Cost Saving Service
        </h3>
        <div class="container entry-content mt-4">
          <p>
          ReservOps.ai reduces AWS costs 35% in average within 2 minutes by optimizing reservations and guiding devops practitioners through smart AI-assistance, which allows them to manage reservations continuously in optimum set of parameters without spending time and budget
          </p>
        </div>
      </div>
    </div>
    <Footer></Footer>
    </div>
  );
}

export default Welcome;