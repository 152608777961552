import React, {useState} from 'react';
import Slider from 'rc-slider';

const styles = {
  handleStyle: {
    top: '0.35em', 
    height: '1.5em', 
    width: '1.5em',
    border: '0.4em solid #fff',
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.13), rgba(255, 255, 255, 0))',
    boxShadow: '0 0 5px #194b88'
  },
  dotStyle: {
    display: 'none'
  },
  activeDotStyle: {
    display: 'none'
  },
  trackStyle: {
    height: '1em',
    backgroundImage: 'linear-gradient(90deg, #4CA5DC, #194b88)'
  },
  railStyle: {
    height: '1em'
  },
  markStyle: {
    fontSize: '20px',
    padding: '0.5em',
    top: '-3em'
  },
  limitsMarkStyle: {
    fontSize: '14px',
    padding: '0.25em'
  },
  sliderValue: {
    textAlign: 'center',
    padding: 0,
  },
  sliderTitle: {
    textAlign: 'center',
    paddingTop: '2em',
    marginBottom: '0',
  },
}

const wrapperStyle = { margin: 40 };

const BillingSlider = (props) => {
  const [billing, setBilling] = useState(3000);
  
  const billingMarks = {
    0: {style: styles.limitsMarkStyle, label: '0$' },
    10000: {style: styles.limitsMarkStyle, label: '$10,000.00'},
  };
  billingMarks[billing] = {style: styles.markStyle, label: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(billing)};

  const handleBillingChange = (value) => {
    props.setBilling(value);
    setBilling(value);
  };
  
  return (<div>
    {props.platform.length > 0
      ? <h5 style={styles.sliderTitle}>{`How much do you pay to ${props.platform} monthly?`} </h5>
      : <h5 style={styles.sliderTitle}>{`How much do you pay monthly?`} </h5> 
    }
    <div style={wrapperStyle}>
      <Slider 
        handleStyle={styles.handleStyle} 
        activeDotStyle={styles.activeDotStyle}
        dotStyle={styles.dotStyle} 
        trackStyle={styles.trackStyle} 
        railStyle={styles.railStyle} 
        min={0}
        max={10000}
        defaultValue={3000}
        marks={billingMarks} 
        step={1}
        onChange={handleBillingChange}
      />
    </div>
  </div>);
}

export default BillingSlider;