import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';

import Home from './pages/Home';
import About from './pages/About';
import WhatIsMaxspotter from './pages/WhatIsMaxspotter';
import Features from './pages/Features';
import WhoIsItFor from './pages/WhoIsItFor';
import Agencies from './pages/Agencies';
import Brands from './pages/Brands';
import Developers from './pages/Developers';
import HowIsItDifferent from './pages/HowIsItDifferent';
import HowItWorks from './pages/HowItWorks';
import Careers from './pages/Careers';
import Investors from './pages/Investors';
import withScrollToTop from './components/withScrollToTop';
import Contact from './pages/Contact';
import EarlyAccess from './pages/EarlyAccess';
import EarlyAccessTwo from './pages/EarlyAccessTwo';
import EarlyAccessThree from './pages/EarlyAccessThree';
import EarlyAccessFour from './pages/EarlyAccessFour';
import FreeCredits from './pages/FreeCredits';
import Welcome from './pages/Welcome';
import CookieConsent from './components/CookieConsent';
import CookiePolicy from './pages/CookiePolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import DataScientis from './pages/DataScientists';
import Devops from './pages/Devops';
import AllUsers from './pages/AllUsers';
import IncubationAccess from './pages/IncubationAccess';
import FAQ from './pages/FAQ';
import WhyBusinessTeams from './pages/WhyBusinessTeams';
import WhyEngineeringTeams from './pages/WhyEngineeringTeams';


const RouteWithScrollToTop = withScrollToTop(Route);

function App() {
  return (
    <div id='app-top' className="App">
      <Router>
      <CookieConsent></CookieConsent>
        <Switch>
          <RouteWithScrollToTop path="/features">
            <Features />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/what-is-maxspotter'>
            <WhatIsMaxspotter />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/who-is-it-for">
            <WhoIsItFor />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/agencies">
            <Agencies />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/brands">
            <Brands />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/developers">
            <Developers />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/how-is-it-different">
            <HowIsItDifferent />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/how-it-works">
            <HowItWorks />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/about">
            <About />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/careers">
            <Careers />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/investors">
            <Investors />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/contact'>
            <Contact />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/get-started-start'>
            <EarlyAccess />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/get-started-instance'>
            <EarlyAccessTwo />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/get-started-cost'>
            <EarlyAccessThree />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/incubation'>
            <IncubationAccess />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/get-started-summary'>
            <EarlyAccessFour />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/free-credits'>
            <FreeCredits />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/welcome'>
            <Welcome />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/data-scientists'>
            <DataScientis />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/devops'>
            <Devops />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/all-users'>
            <AllUsers />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/cookie-policy'>
            <CookiePolicy />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/privacy'>
            <PrivacyPolicy />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/FAQ'>
            <FAQ />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/why-engineering-teams'>
            <WhyEngineeringTeams />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path='/why-business-teams'>
            <WhyBusinessTeams />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/">
            <Home />
          </RouteWithScrollToTop>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
