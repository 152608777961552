import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const WhatIsMaxspotter = (props) => {
  return (
    <div>
      <Header sectionHeading='What is MaxSpotter?'></Header>
      <div className="align-items-center my-lg text-center">
      <div className="container">
        <h3 className="mb-4">
          We Help AnyOne on the cloud
        </h3>
        <div className="container entry-content col-lg-12">
          <p>
            MaxSpotter is a continous cloud cost workload optimization tool
            implementing an automated bidding algorithm against the Amazon AWS
            EC2 spot market, which often gives you much cheaper spot instances,
            allowing you to generate significant savings, often in the 70-90%
            range over on-demand.
          </p>

          <h3>
            What are spot instances, what is the spot market and how does it
            work?
          </h3>
          <p>
            Cloud providers such as Amazon AWS need to always have some spare
            capacity available, so that any customer willing to launch new
            compute machines would be able to do it without getting errors.
            Normally this capacity would be sitting idle but still consuming
            power until someone needs to use it.
          </p>
          <p>
            Instead of wasting this idle capacity, Amazon created a marketplace
            for anyone willing to pay some money in order to use these machines,
            but knowingly taking the risk that this spare capacity may be taken
            back within minutes when it needs to be allocated to an ordinary
            on-demand user.
          </p>
          <p>
            This is known as the spot market, and these volatile compute
            machines are called spot instances.
          </p>
          <p>
            The market automatically computes a price based on the current
            supply and demand, updated daily, and everyone from the same
            AvailabilityZone (note: availability zone names may not necessarily
            match between different AWS accounts) within a region pays the same
            price for a given instance type.
          </p>
          <p>
            Luckily the market price is most of the times a number of times less
            than the normal, on-demand price, sometimes up to 10 times less, but
            usually in the 5x-7x range. In many cases the spot capacity is so
            stable that spot instances may run for weeks or even months at about
            80% savings, so it's basically a waste of money not to run spot
            instances there. This sounds too nice to be true, and it almost is.
            In case of demand surges or maintenance events in which some of the
            capacity is reclaimed. The spot instances are terminated with a two
            minute notice when AWS needs that capacity for on-demand users. In
            those cases it would obviously be better to fall back to the normal
            on-demand instances in order not to cause user impact for your
            application.
          </p>
          <p>
            Since each availability zone and instance type combination has a
            different price graph, in case one of them became too pricy and
            terminated all its instances, it is possible to find another cheap
            one which may still run your workload, at least for a while. With
            enough redundancy(which should be in place anyway where it really
            matters), a surprisingly large amount of applications are able to
            migrate the user traffic to surviving machines in the event of spot
            instance terminations on some of the availability zones. One could
            generate a lot of savings if somehow could protect against these
            unexpected terminations, like by quickly switching to another
            instance type or even falling back to on-demand instances for
            relatively brief periods of time. MaxSpotter is implementing exactly
            this kind of automation of switching between different spot instance
            types, also falling back to on demand during price surges.
          </p>
          <p>
            MaxSpotter is a cloud cost optimization tool implementing bidding
            automation against the AWS APIs.
          </p>
          <p>
            It predicts best bids for maximum capacity and lowest interruption
            rates. Provides long running instances with upto 60 days.
          </p>
          <p>
            It monitors some of your AutoScaling groups where it was enabled and
            it continuously replaces any on-demand instances found in those
            groups with compatible and identically configured spot instances. It
            can also keep some of them running.
          </p>
        </div>
      </div>
    </div>
    <section className="jumbotron sub-page text-center d-flex align-items-center">
      <div className="container">
        <h3 className="display-5">Supported MaxSpotter Cloud Platforms</h3>
        <p>
          We work with AWS for the moment. We’ll support Azure and Google Cloud
          Platform soon.
        </p>
      </div>
    </section>
    <Footer></Footer>
    </div>
  );
}

export default WhatIsMaxspotter;