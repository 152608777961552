/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import budgetIcon from '../images/budget-icon-10.png'
import inventoryIcon from '../images/inventory-icon.png'
import discountIcon from '../images/discount-icon.png'


const WhyBusinessComponents = (props) => {

  const renderRegularCards = (
      <div className="row d-flex justify-content-center col-12 p-0 m-0">
          <div className="col-12 col-md-6 col-lg-3 pb-4">
            <div className="card pricing mx-3 ml-md-0 m-lg-0">
                <div className="col-12">
                <i class="fas fa-file-invoice-dollar"></i>
                  <img width="100%" height="auto" alt="" src={budgetIcon}/>
                  <h4 className="mb-5 mt-5"><b>Groups</b></h4>
                  <p className="mt-4 mb-5">Create groups based around the organization of your company and send relevant team members information on budgets, forecasts, and costs.</p>
                </div>
            </div>
          </div>
          <div  className="col-12 col-md-6 col-lg-3 pb-4">
          <div className="card pricing mx-3 ml-md-0 m-lg-0 h-100">
              <div className="col-12">
                <img width="100%" height="auto" alt="" src={inventoryIcon}/>
                <h4 className="mb-4 mt-4"><b>Reservation Inventory</b></h4>
                <span>View all reservations for all services in one place. See which reservations are expiring and how each reservation has been utilized to understand your purchase.</span>
              </div>
           </div>
          </div>
          <div  className="col-12 col-md-6 col-lg-3 pb-4">
          <div className="card pricing mx-3 ml-md-0 m-lg-0 h-100">
                <div className="col-12">
                  <img alt="" width="100%" height="auto" src={discountIcon}/>
                  <h4 className="mb-4"><b>Discount Attribution</b></h4>
                  <span>See where discounts and upfront capital are being used to optimize tax write-offs and amortization.</span>
                </div>
            </div>
          </div>
        </div>
  );

  return (
    <div id="who-is-it-for" className="section bg-gradient-primary">
    <div className="container">
      <div className="row">
        {props.headerText && <div style={{color: 'white'}} className="col-12 section-title text-center mb-5">
          <h3>{props.headerText}</h3>
          <p>
          </p>
        </div>}
        <div>
        {props.disabledCard > 0 ? renderRegularCards  : renderRegularCards}
        </div>
      </div>
    </div>
  </div>
  ); 
}

export default WhyBusinessComponents;
