import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useLocation, useHistory } from 'react-router-dom';
import { sendData } from '../service/service';
import ConsentCheckboxes from '../components/ConsentCheckboxes.';

const styles= {
  headingContainer: {
    margin: '3em auto'
  },
  sliderTitle: {
    textAlign: 'center',
    marginBottom: '2em',
  },
  nextBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2em'
  },
  otherPlatformForm: {
    margin: `2em 0`,
  },
  formField: {
    width: '100%',
    height: 'auto'
  },
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const EarlyAccessFour = (props) => {
  const query = useQuery();
  const history = useHistory();
  const origin = query.get('origin');
  const platform = (query.get('platform'));
  const instanceCount = (query.get('instanceCount'));
  const billing = (query.get('billing'));
  const type = query.get('type');
  if (!platform || !instanceCount || !billing) {
    history.replace('/');
  }
  const [customerEmail, setCustomerEmail] = useState('');
  const [errorLimit, setErrorLimit] = useState(0);

  const [ consentChecked, setConsentChecked ] = useState(false);
  const [ communicationsChecked, setCommunicationsChecked ] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault(true);
    if (consentChecked) {
      sendData({
        userPlatform: platform,
        userInstanceCount: instanceCount,
        userBilling: billing,
        userEmail: customerEmail,
        tag: `get-started:${origin}`,
        tags: [`get-started:${origin}`, 'get-started'],
        customerTags: {type},
        processConsent: consentChecked,
        communicationsConsent: communicationsChecked,
      }).then((res) => {
        if (res.data && res.data.redirection && res.data.redirectTo) {
          window.location.replace(res.data.redirectTo);
        } else if (res.data && res.data.redirection) {
          window.location.replace(`${process.env.REACT_APP_DASHBOARD_LINK}/login`);
        } else {
          history.push('/welcome');
        }
      }).catch((err) => {
        console.log(err);
        if (errorLimit > 0) {
          setErrorLimit(errorLimit - 1);
          alert('An error occured. Please try again.');
        } else {
          history.push('/welcome');
        }
      });
    } else {
      alert('We need your consent to continue.');
    }
  };

  const handleCustomerEmailChange = (event) => {
    setCustomerEmail(event.target.value);
  }

  const handleConsentChecked = (value) => {
    setConsentChecked(value);
  }

  const handleCommunicationsChecked = (value) => {
    setCommunicationsChecked(value);
  }

  return (
    <div>
      <Header
        sectionHeading='Get Started Now!' 
        sectionBodyText={`Start Lowering Your Bills Now For Free!`}
      />
      <div className="d-flex container  justify-content-center align-items-center" style={styles.headingContainer}>
        <div className="col-8">
        <div className="row justify-content-md-center">
          {/* <h4 style={styles.headingStyle}>{`We need some info and will get in touch soon.`} </h4> */}
            <div className="col col-md-9">
              <div className="card pricing m-0">
                  <div className="row m-0 p-0">
                    <div className="col-9 p-0">
                      <h5 style={{...styles.sliderTitle, textAlign: 'left'}}>{`Cloud Provider:`}</h5>
                    </div>
                    <div className="col-3 p-0">
                     <h5 style={{...styles.sliderTitle, textAlign: 'right'}}>{`${platform}`}</h5>
                    </div>
                  </div>
                  <div className="row m-0 p-0">
                    <div className="col-9 p-0">
                      <h5 style={{...styles.sliderTitle, textAlign: 'left'}}>{`Monthly Billing:`}</h5>
                    </div>
                    <div className="col-3 p-0">
                     <h5 style={{...styles.sliderTitle, textAlign: 'right'}}>{`$${billing}`}</h5>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit} className="form-group w-100 row">
            <div className="col-12 align-items-center d-flex">
              <input 
                  style={styles.formField} 
                  className="form-group form-control formField mb-lg-2" 
                  type="email" 
                  placeholder="Email*"
                  value={customerEmail} 
                  onChange={handleCustomerEmailChange}
                  required
                />
              </div>
              <ConsentCheckboxes defaultConsent={false} defaultCommunications={true} setCommunicationsChecked={handleCommunicationsChecked} setConsentChecked={handleConsentChecked} />
              <div className="col-12 align-items-center d-flex">
                <button disabled={!consentChecked} style={{cursor: 'pointer'}} type="submit" class="w-100 btn btn-primary mb-lg-2">Get Started</button>
              </div>
            </form>
              </div>
          </div>
        </div>                
        </div>
      </div>
      <Footer></Footer>
    </div>
  ); 
}

export default EarlyAccessFour;
