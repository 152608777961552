import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PlatformSelector from "../components/PlatformSelector";

const styles = {
  headingContainer: {
    margin: "4em auto",
  },
  sliderTitle: {
    textAlign: "center",
    marginBottom: "2em",
  },
  nextBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2em",
  },
};

const IncubationAccess = (props) => {
  const [platform, setPlatform] = useState("AWS");
  const [/* otherPlatform, */ setOtherPlatform] = useState("");

  const handleOtherPlatformChange = (value) => {
    setOtherPlatform(value);
  };

  const handlePlatformChange = (key) => {
    setPlatform(key);
  };

  return (
    <div>
      <Header
        sectionHeading="Startups! Rejoice!"
        sectionBodyHeading="Being a Startup is hard, we know! Start reducing your costs for free now. "
      />
      <div
        className="d-flex container  justify-content-center align-items-center"
        style={styles.headingContainer}
      >
        <div className="col-8">
          <h4 style={styles.sliderTitle}>
            {`Which cloud platform do you use?`}
          </h4>
          <PlatformSelector
            forBeta={true}
            platform={platform}
            setPlatform={handlePlatformChange}
            setOtherPlatform={handleOtherPlatformChange}
          ></PlatformSelector>
            <a
              href={`${process.env.REACT_APP_DASHBOARD_LINK}/register` || ''}
              style={styles.nextBtn}
              className="btn btn-xl btn-outline-primary-maxspotter mx-auto mb-3"
            >
              Next
            </a>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default IncubationAccess;
