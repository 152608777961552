/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import appinizerLogo from "../images/apinizer-logo.png";
import poltioLogo from "../images/poltio-logo.png";
import puzzlemediatechLogo from '../images/puzzlemediatech-logo.png';
import lidyanaLogo from '../images/lidyana-logo.png';
import publinsLogo from '../images/publins-logo.png';
import istegelsinLogo from '../images/istegelsin-logo.png';
import onePager from '../images/main_one_pager.png';
import Footer from '../components/Footer';
import HeaderNav from '../components/HeaderNav';
import PlatformComponent  from '../components/PlatformComponent';
//import PlatformSelector from '../components/PlatformSelector';
import StatsSection from '../components/StatsSection';
import KeyFeaturesComponent from '../components/KeyFeaturesComponent';
import PricingSection from '../components/PricingSection';
import { VideoModal } from '../components/VideoModal';


const dataOnePager={
  img: onePager,
};

function Home() {
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);

  const openVideoModal = () => {
    setVideoModalOpen(true);
  }

  const closeVideoModal = () => {
    setVideoModalOpen(false);
  }
  return (
    <div style={{overflow: 'hidden'}}>
      {/* <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NV4MML7"
      height="0" width="0" style={{display: "none", visibility: "hidden"}}></iframe></noscript> */}
    <header style={{overflow: 'hidden'}} id="home">
      <HeaderNav
        hasFeaturesSection={true}
        hasPricingSection={true}
      />
      <section style={{overflow: 'hidden', marginTop: '5rem'}} className="jumbotron-two">
        <div className="container">
          <div className="row mb-md-5 d-flex align-items-center">
            <div style={{marginTop: '3em'}} className="col-12 col-md-5 text-center text-md-left">
              <h1 className="display-5">The World's Fastest <p>AWS Cost Saving Service</p></h1>

              <h5 className="text-muted mt-3">
                {/* 
                ReservOps.ai reduces AWS costs 35% in average within 2 minutes 
                by optimizing reservations and guiding devops practitioners through 
                smart AI-assistance, which allows them to manage reservations continuously in 
                optimum set of parameters without spending time and budget
                <br />
                <br /> */}
                Start reducing your costs within the next 2 minutes.
                {/* <br />
                <div className="float-right"> 
                  <a
                    className="btn btn-link"
                    href="https://blog.maxspotter.com/why-reservops-ai-is-the-worlds-fastest/"
                    id=""
                  >
                    Read More
                  </a>
                </div> 
                  */}
              <br />
              </h5>
              <div className="row mt-5 pt-5">
                <div className="col-12">
                    <a
                      className="btn btn-xl btn-primary"
                      href={`${process.env.REACT_APP_DASHBOARD_LINK}/register` || ''}
                      id="calendly-trigger"
                    >
                      Get Started for Free
                    </a>
                </div>
                <div className="col-12 p-4">
                  <a
                    className="fa-sm"
                    href="#"
                    onClick={openVideoModal}
                  >
                    Watch our latest video
                  </a>
                </div>
                <VideoModal
                autoplay={true}
                  show={isVideoModalOpen}
                  src='https://www.youtube.com/embed/bDNnFLM64jo'
                  onHide={closeVideoModal}
                />
              </div>
                {/* <div className="media-icon d-flex mr-3 mb-3">
                <i className="pe-7s-paint-bucket pe-3x"></i>
                <p className="text-muted">
                Easy Installation
                </p>
              </div>
              <div className="media-icon d-flex mr-3 mb-3">
                <i className="pe-7s-shield pe-3x"></i>
                <p className="text-muted">
                Easy Installation
                </p>
              </div>
              <div className="media-icon d-flex mr-3 mb-3">
                <i className="pe-7s-piggy pe-3x"></i>
                <p className="text-muted">
                Up to 90% Cost Savings
                </p>
              </div> */}
            </div>
            <div className="col-12 col-md-7 mt-5 p-0 px-4 d-flex align-items-center">
            <div style={{boxShadow: '0 5px 20px rgba(0, 0, 0, 0.2)'}} className="card pricing m-0">
              <div className="card-body p-0">
              {/* <div className="section-title text-center my-0">
          <h4>How Much I Can Save with MaxSpotter?</h4>
        </div> */}
        <PlatformComponent>data={dataOnePager}</PlatformComponent>
              </div>
            </div>
      </div>
            {/* <div className="col-12 col-md-7 my-3 my-md-2">
              <div
                className="youtube cast-shadow"
                data-video-id="_AoZRDVlKDs"
                data-params=""
              >
                <img src={mainBg} alt="image" className="img-fluid" />
                <div className="play"><span className="pe-7s-play pe-3x"></span></div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="row d-block">
        </div>
      </section>

      <div className="bg-shape"></div>
      {/*<div className="bg-circle"></div>
      <div className="bg-circle-two"></div> */} 
    </header>  
    <div className="section mb-0 pb-0" id="intro">
      <div className="container mb-0 pb-0">
        <div className="client-logos text-center mb-0 pb-0" id="brands">
          <p className="text-muted">Trusted by 100+ teams in all around the world</p>
          <img src={appinizerLogo} alt="client logo" />
          <img src={poltioLogo} alt="client logo" />
          <img src={lidyanaLogo} alt="client logo" />
          <img src={puzzlemediatechLogo} className="client logo" alt="client logo" />
          <img src={publinsLogo} className="client logo mx-3" alt="publins logo" />
          <img src={istegelsinLogo} className="client logo m-3" alt="istegelsin logo" />
        </div>
      </div>
    </div>


    {/* <div className="container my-0">
    <hr className="my-0"></hr>
    </div> */}

    <div className="section" id="intro">
      <div className="container d-flex justify-content-center">
        <div className="text-center col-12 col-md-8" id="how-it-works">
          <h3 className="m-4">How it Works?</h3>
          <h5 className="">
          ReservOps.ai reduces AWS costs 35% in average within 2 minutes 
                by optimizing reservations and guiding devops practitioners through 
                smart AI-assistance, which allows them to manage reservations continuously in 
                optimum set of parameters without spending time and budget
                <br />
                <br />
                <div className=""> 
                  <a
                    className="btn btn-link"
                    href="https://blog.reservops.ai/why-reservops-ai-is-the-worlds-fastest/"
                    id=""
                  >
                    Read More
                  </a>
                </div> 
                 
          </h5>
        </div>
      </div>
    </div>
    
    <StatsSection disabledCard={0}></StatsSection>
    <div className="section bg-light" id="key-features">
      <div className="container">
        <div className="row">
          <KeyFeaturesComponent />
        </div>
      </div>
    </div>  
    <div className="section bg-gradient-primary" id="pricing">
      <PricingSection></PricingSection>
    </div>
    <Footer></Footer>
  </div>
  )
}

export default Home;
