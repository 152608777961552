/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WhyBusinessComponents from '../components/WhyBusinessComponents';
import WhyBusinessComponents2 from '../components/WhyBusinessComponents2';
import ContentBand from '../components/ContentBand';



// const data = {
//   features:[{
//       //logo: istegelsinLogo,
//       title: "Groups",
//       explaination: "Create groups based around the organization of your company and send relevant team members information on budgets, forecasts, and costs."
//     },
//     {
//       //logo: istegelsinLogo,
//       title: "Reservation Inventory",
//       explaination: "View all reservations for all services in one place. See which reservations are expiring and how each reservation has been utilized to understand your purchase."
//     },
//     {
//       //logo: istegelsinLogo,
//       title: "Discount Attribution",
//       explaination: "See where discounts and upfront capital are being used to optimize tax write-offs and amortization."
//     }
//   ]
// };


function WhyBusinessTeams() {
  return (
    <div>
    {/* <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NV4MML7"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> */}
    <Header sectionHeading='Solutions for your business' />
    <div className="align-items-center my-lg text-center">
      <div className="container-fluid p-0">
        <div className="container d-flex justify-content-center">
        <div className="col-12 col-md-8 text-center pb-5">
          <p className="mb-4">We save engineering and FinOps teams hundreds of hours through our end-to-end, automation first approach that simplifies tasks like cost attribution, executing exchanges, re-sale, renewals, and reservation purchases.</p>
            <a
              className="btn btn-xl btn-primary"
              href={`${process.env.REACT_APP_DASHBOARD_LINK}/register` || ''}
            >
            Get Started For Free
            </a>
      </div>
        </div>
      <div className="col-12 p-0 m-0">
          <WhyBusinessComponents disabledCard={0}></WhyBusinessComponents>
      </div>
      <div className="container d-flex justify-content-center">
        <div className="col-12 col-md-8">
          <div class="p-4 text-center">
            <h4 className="my-3"><b>Never miss an actionable savings opportunity.</b></h4>
            <br></br>
            <p>
              Finding opportunities for savings alone will not generate savings unless the necessary purchasing, exchanging, and renewing actions are executed. ReservOps.ai allows you to automate these processes to ensure that you never leave money on the table
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid d-flex justify-content-center m-0 p-0">
        <div className="col-12 p-0">
            <WhyBusinessComponents2 disabledCard={0}></WhyBusinessComponents2>
        </div>
      </div>
    <hr></hr> 
    <ContentBand  sectionHeading='Start with our free dashboard' />
    <div className="container d-flex justify-content-center">
      <div className="col-12 col-md-8">
        <div class="p-4 text-center">
          <h4 className="my-5">
          Get a risk-free analysis of your potential savings opportunities in less than 10 minutes by installing our free dashboard using minimally permissive, read-only IAM credentials.
          </h4>
            <a
              className="btn btn-xl btn-primary"
              href={`${process.env.REACT_APP_DASHBOARD_LINK}/register` || ''}
              id="calendly-trigger"
            >
            Get Started For Free
            </a>
        </div>
      </div>  
    </div>
    </div>
    </div>
    <Footer></Footer>
    </div>
  )
}

export default WhyBusinessTeams;
