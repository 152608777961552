import React from 'react';
import HeaderSection from '../components/HeaderSection';
import HeaderNav from '../components/HeaderNav';

const Header = (props) => {

  return (
    <header
      data-parallax="scroll"
      data-speed="0.5"
      data-image-src="images/hero.jpg"
    >
      {!props.disableHeaderNavSection && <HeaderNav/>}
      {!props.disableHeaderSection && <HeaderSection 
        sectionHeading={props.sectionHeading}
        sectionBodyHeading={props.sectionBodyHeading}
        sectionBodyText={props.sectionBodyText}
        hasFeaturesSection={props.hasFeaturesSection}
        hasPricingSection={props.hasPricingSection}
      />}
    </header>
  );
}

export default Header;