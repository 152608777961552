/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

function PrivacyPolicy() {

  const [language, setLanguage] = React.useState('TR');

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  }

  return (
    <div>
    <Header sectionHeading={language === 'TR' ? 'Gizlilik Sözleşmesi' : 'Privacy Policy'} />
    <div className="align-items-center my-lg text-center">
    <div className="container text-left px-4 pb-4">
    <select
      onChange={handleLanguageChange}
      >
  <option value="TR">TR</option>
  <option disabled value="EN">EN</option>
  <option disabled value="DE">DE</option>
</select>
    </div>
      <div className="container">
        {language === 'TR' ?
          <div className="container entry-content text-justify">
          <p>
          İşbu Gizlilik Politikası’nın amacı, www.maxspotter.com, www.reservops.ai, console.maxspotter.com ve console.reservops.ai adresinde yer alan siteye (“Site”) üye olarak kaydolan kişiler (“Kullanıcı(lar)”) tarafından Site’de yer alan Platform’dan faydalanılması aşamasında FAAS BİLİŞİM TEKNOLOJİLERİ LİMİTED ŞİRKETİ (“Şirket”) ile paylaşılan bilgi ve verilerin kullanımına ilişkin koşul ve şartları tespit etmektir. Gizlilik Politikası, Kullanıcı ile akdedilen Kullanıcı Sözleşmesi’nin eki ve ayrılmaz bir parçası niteliğindedir.
          including mobile identifiers such as Apple’s IDFA or Google’s 
          Advertising ID) and similar technology. Cookies are in connection with your use of the Service, third party websites, and mobile applications. Cookies may have unique identifiers, and reside, among other places, on your computer or mobile device, in emails we send to you, and on our web pages. Cookies may transmit information about you and your use of the Service, such as your browser type, search preferences, IP address, data relating to advertisements that have been displayed to you or that you have clicked on, and the date and time of your use. Cookies may be persistent or stored only during an individual session.
          </p>
          <p>
          Kullanıcı tarafından Site’ye yüklenen İçerik Kullanıcının mülkiyetindedir. Şirket, bu İçerik’i Kullanıcı’nın önceden onayını almaksızın açıklamayacak veya satmayacaktır.
          </p>
          <p>
            'Şirket, Site üzerinden kendisine elektronik ortamdan iletilen İçerikleri’i Kullanıcılar ile yaptığı Kullanıcı Sözleşmesi ile belirlenen amaçlar ve kapsam dışında üçüncü kişilere açıklamayacaktır. Bu kapsamda Şirket, İçerikler’i kesinlikle özel ve gizli tutmayı, bunu bir sır saklama yükümü olarak addetmeyi ve gizliliğin sağlanması ve sürdürülmesi, gizli bilginin tamamının veya herhangi bir kısmının kamu alanına girmesini veya yetkisiz kullanımını veya üçüncü bir kişiye ifşasını önlemek için gerekli tüm tedbirleri almayı ve gerekli özeni göstermeyi taahhüt etmektedir.
          </p>
          <p>
          Kullanıcı’nın Şirket’e onay vermesi halinde ödeme işlemleri, fatura kesimi ve işlenmesi, Kullanıcı’nın erişimine izin verdiği üçüncü kişi uygulamaları ve doğrudan pazarlama gibi amaçlarla Kulllanıcı’nın verdiği onay çerçevesinde İçerikler işlenebilir, saklanabilir ve Şirket ile ortakları tarafından Kullanıcı’nın talep ettiği hizmetleri sağlamak üzere üçüncü kişilere devredilebilir.
          </p>
          <p>
          Kişisel bilgiler Kullanıcı ile temas kurmak veya Kullanıcı’nın Site’deki tecrübesini iyileştirmek (hizmetlere ilişkin bakım ve destek, mevcut hizmetlerin geliştirilmesi, yeni hizmetler oluşturulması ve kişiye özel hizmetler sunulması gibi) amacıyla kullanılabileceği gibi veri tabanı oluşturma, üyeliklerin sürdürülmesi ve Şirket’in hizmetlerinin Kullanıcı’ya sağlanması için gerekli olan diğer faaliyetleri gerçekleştirme amacıyla da işleyebilecek, saklayabilecek ve üçüncü kişilere aktarabilecektir.
          </p>
          <p>
          Şirket, Kullanıcı’nın Site üzerinde gerçekleştirdiği kullanım ve işlem bilgilerini anonim hale getirerek; istatistiki değerlendirmelerde, performans değerlendirmelerinde, Şirket ve iş ortaklarının pazarlama kampanyalarında ve bağış kampanyalarında, yıllık rapor ve benzeri raporlarda kullanmak üzere bu amaçların gerçekleştirilmesi için gereken sürede saklayabilir, işleyebilir ve iş ortaklarına iletebilir.
          </p>
          <p>
          Site üzerinden düzenlenebilecek olan periyodik veya periyodik olmayan anketlere cevap veren ve kişisel bilgilerinin işlenmesi konusunda rıza gösteren Kullanıcılar’dan talep edilen bilgiler, Şirket tarafından bu kişilere doğrudan pazarlama yapmak, istatistiki analiz yapmak ve veri tabanı oluşturmak amacıyla kullanılabilecektir.
          </p>
          <p>
          Şirket ayrıca, aşağıdaki koşulların varlığı halinde Kullanıcı’ya ait bilgileri üçüncü kişilerle paylaşabilecektir:
          </p>
          <p>
            <ul>
              <li>
              Kullanıcı Sözleşmesi kapsamındaki yükümlülüklerin yerine getirilmesi için ilgili ifşanın zorunlu olması,
              </li>
              <li>
              Yetkili idari ve adli bir kurum tarafından ilgili mevzuat doğrultusunda usulüne göre yürütülen bir araştırma veya soruşturmanın yürütümü amacıyla kullanıcılarla ilgili bilgi talep edilmesi,
              </li>
              <li>
              Kullanıcıların hakları veya güvenliklerini korumak için bilgi vermenin gerekli olması.
              </li>
            </ul>
          </p>
          <p>
          Sistemle ilgili teknik sorunların tanımlanması ve çözülmesi için, Şirket gereken hallerde kullanıcıların IP adresini tespit etmek ve kullanmak durumunda kalabilir. IP adresleri, ayrıca kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak amacıyla da kullanılabilir.
          </p>
          <p>
          Şirket, yukarıda anılan amaçlarla ilgili verileri Kullanıcı’nın ikamet ettiği ülke dışında dünyanın herhangi bir yerinde bulunan sunucularına (sunucular kendisine, bağlı şirketlerine veya alt yüklenicilerine ait olabilir) aktarma hakkına sahiptir.
          </p>
          <p>
          Site üzerinden başka site ve uygulamalara link verilmesi mümkün olup, Şirket’in bu site ve uygulamaların gizlilik uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır.
          </p>
          <p>
          Şirket, işbu Gizlilik Politikası hükümlerini dilediği zaman Site’de yayımlamak suretiyle değiştirebilir. Şirket’in değişiklik yaptığı Gizlilik Politikası hükümleri Site’de yayınlandığı tarihte yürürlük kazanır.
          </p>
        </div>
        : <div className="container entry-content text-justify">
        <p>
        İşbu Gizlilik Politikası’nın amacı, www.maxspotter.com, www.reservops.ai, console.maxspotter.com ve console.reservops.ai adresinde yer alan siteye (“Site”) üye olarak kaydolan kişiler (“Kullanıcı(lar)”) tarafından Site’de yer alan Platform’dan faydalanılması aşamasında FAAS BİLİŞİM TEKNOLOJİLERİ LİMİTED ŞİRKETİ (“Şirket”) ile paylaşılan bilgi ve verilerin kullanımına ilişkin koşul ve şartları tespit etmektir. Gizlilik Politikası, Kullanıcı ile akdedilen Kullanıcı Sözleşmesi’nin eki ve ayrılmaz bir parçası niteliğindedir.
        including mobile identifiers such as Apple’s IDFA or Google’s 
        Advertising ID) and similar technology. Cookies are in connection with your use of the Service, third party websites, and mobile applications. Cookies may have unique identifiers, and reside, among other places, on your computer or mobile device, in emails we send to you, and on our web pages. Cookies may transmit information about you and your use of the Service, such as your browser type, search preferences, IP address, data relating to advertisements that have been displayed to you or that you have clicked on, and the date and time of your use. Cookies may be persistent or stored only during an individual session.
        </p>
        <p>
        Kullanıcı tarafından Site’ye yüklenen İçerik Kullanıcının mülkiyetindedir. Şirket, bu İçerik’i Kullanıcı’nın önceden onayını almaksızın açıklamayacak veya satmayacaktır.
        </p>
        <p>
          'Şirket, Site üzerinden kendisine elektronik ortamdan iletilen İçerikleri’i Kullanıcılar ile yaptığı Kullanıcı Sözleşmesi ile belirlenen amaçlar ve kapsam dışında üçüncü kişilere açıklamayacaktır. Bu kapsamda Şirket, İçerikler’i kesinlikle özel ve gizli tutmayı, bunu bir sır saklama yükümü olarak addetmeyi ve gizliliğin sağlanması ve sürdürülmesi, gizli bilginin tamamının veya herhangi bir kısmının kamu alanına girmesini veya yetkisiz kullanımını veya üçüncü bir kişiye ifşasını önlemek için gerekli tüm tedbirleri almayı ve gerekli özeni göstermeyi taahhüt etmektedir.
        </p>
        <p>
        Kullanıcı’nın Şirket’e onay vermesi halinde ödeme işlemleri, fatura kesimi ve işlenmesi, Kullanıcı’nın erişimine izin verdiği üçüncü kişi uygulamaları ve doğrudan pazarlama gibi amaçlarla Kulllanıcı’nın verdiği onay çerçevesinde İçerikler işlenebilir, saklanabilir ve Şirket ile ortakları tarafından Kullanıcı’nın talep ettiği hizmetleri sağlamak üzere üçüncü kişilere devredilebilir.
        </p>
        <p>
        Kişisel bilgiler Kullanıcı ile temas kurmak veya Kullanıcı’nın Site’deki tecrübesini iyileştirmek (hizmetlere ilişkin bakım ve destek, mevcut hizmetlerin geliştirilmesi, yeni hizmetler oluşturulması ve kişiye özel hizmetler sunulması gibi) amacıyla kullanılabileceği gibi veri tabanı oluşturma, üyeliklerin sürdürülmesi ve Şirket’in hizmetlerinin Kullanıcı’ya sağlanması için gerekli olan diğer faaliyetleri gerçekleştirme amacıyla da işleyebilecek, saklayabilecek ve üçüncü kişilere aktarabilecektir.
        </p>
        <p>
        Şirket, Kullanıcı’nın Site üzerinde gerçekleştirdiği kullanım ve işlem bilgilerini anonim hale getirerek; istatistiki değerlendirmelerde, performans değerlendirmelerinde, Şirket ve iş ortaklarının pazarlama kampanyalarında ve bağış kampanyalarında, yıllık rapor ve benzeri raporlarda kullanmak üzere bu amaçların gerçekleştirilmesi için gereken sürede saklayabilir, işleyebilir ve iş ortaklarına iletebilir.
        </p>
        <p>
        Site üzerinden düzenlenebilecek olan periyodik veya periyodik olmayan anketlere cevap veren ve kişisel bilgilerinin işlenmesi konusunda rıza gösteren Kullanıcılar’dan talep edilen bilgiler, Şirket tarafından bu kişilere doğrudan pazarlama yapmak, istatistiki analiz yapmak ve veri tabanı oluşturmak amacıyla kullanılabilecektir.
        </p>
        <p>
        Şirket ayrıca, aşağıdaki koşulların varlığı halinde Kullanıcı’ya ait bilgileri üçüncü kişilerle paylaşabilecektir:
        </p>
        <p>
          <ul>
            <li>
            Kullanıcı Sözleşmesi kapsamındaki yükümlülüklerin yerine getirilmesi için ilgili ifşanın zorunlu olması,
            </li>
            <li>
            Yetkili idari ve adli bir kurum tarafından ilgili mevzuat doğrultusunda usulüne göre yürütülen bir araştırma veya soruşturmanın yürütümü amacıyla kullanıcılarla ilgili bilgi talep edilmesi,
            </li>
            <li>
            Kullanıcıların hakları veya güvenliklerini korumak için bilgi vermenin gerekli olması.
            </li>
          </ul>
        </p>
        <p>
        Sistemle ilgili teknik sorunların tanımlanması ve çözülmesi için, Şirket gereken hallerde kullanıcıların IP adresini tespit etmek ve kullanmak durumunda kalabilir. IP adresleri, ayrıca kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak amacıyla da kullanılabilir.
        </p>
        <p>
        Şirket, yukarıda anılan amaçlarla ilgili verileri Kullanıcı’nın ikamet ettiği ülke dışında dünyanın herhangi bir yerinde bulunan sunucularına (sunucular kendisine, bağlı şirketlerine veya alt yüklenicilerine ait olabilir) aktarma hakkına sahiptir.
        </p>
        <p>
        Site üzerinden başka site ve uygulamalara link verilmesi mümkün olup, Şirket’in bu site ve uygulamaların gizlilik uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır.
        </p>
        <p>
        Şirket, işbu Gizlilik Politikası hükümlerini dilediği zaman Site’de yayımlamak suretiyle değiştirebilir. Şirket’in değişiklik yaptığı Gizlilik Politikası hükümleri Site’de yayınlandığı tarihte yürürlük kazanır.
        </p>
      </div>}
      </div>
      <Link to='/contact?origin=cookie-policy'>
        <a
          className="btn btn-xl btn-primary"
          href=""
        >
          Contact Us
        </a>
      </Link>
    </div>
    <Footer></Footer>
    </div>
  )
}

export default PrivacyPolicy;
