import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Investors = (props) => {
  return (
    <div>
      <Header sectionHeading='For Investors' />
      <div className="align-items-center my-lg text-center">
      <div className="container">
        <h3 className="mb-4">
          
        </h3>
        <div className="align-items-center my-lg text-center">
      <div className="container">
        <h3 className="mb-3">
          Help Us Improve Cloud Workload Optimizations
        </h3>
        <div className="container entry-content">
          <p>
            MaxSpotter is a leading continous cloud workload optimization
            company based in Istanbul. We work to provide tools for Cloud
            Solutions, SaaS and PaaS platforms, helping them efficiently manage
            their cloud infrastructure.
          </p>
          <p>
            We aim to minimize cloud costs by using cloud excess capacity (a.k.a
            spot instances) efficiently with the maximum service availability.
            We’re the best tool that provides these services and aim to make
            cloud costs savings upto 90%.
          </p>
          <p>
            Our team is constantly growing, and so is our platform: working with
            thousands of servers per day. Potential investors can get in touch
            by emailing <a href="mailto:max@maxspotter.com"
              >max@maxspotter.com</a
            >
            for more information.
          </p>
        </div>
      </div>
    </div>
      </div>
    </div>
      <Footer></Footer>
    </div>
  );
}

export default Investors;