import React, { Component } from "react";
import twitterLogo from "../images/twitter-logo.png";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = this.props.data.image;
    const shorttitle = this.props.data.shorttitle;
    const bio = this.props.data.bio;
    const link = this.props.data.link;
  

    return (
      <section id="about">
          <div className="row">
            <div className="col">
              <img
                className="profile-pic"
                src={profilepic}
                href={link}
                alt=""
              />
              <h4>{name}</h4>
              <h5>{shorttitle}</h5>
              <p>{bio}</p>
              <a href={link}>
                <img
                  src={twitterLogo}
                  class="img-thumbnail"
                  href={link}
                  alt="twitter profile"
                />
              </a>
            </div>
          </div>
      </section>
    );
  }
}

export default About;