import React from 'react';
import { Modal } from 'react-bootstrap';

// const styles = {
//   modal: {
//     position: 'absolute !important',
//     top: '0 !important',
//     left: '0 !important',
//     bottom: '0 !important',
//     right: '0 !important',
//     display: 'flex !important',
//     justifyContent: 'center !important',
//     alignItems: 'center !important',
//   },
//   iframe: {
//     position: 'relative',
//     overflow: 'hidden',
//     width: '100%',
//     paddingTop: '56.25%', // 16:9 Aspect Ratio (divide 9 by 16 = 0.5625)
//   }
// };

export const VideoModal = (props) => {

  return (
    <Modal
    {...props}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    className="forceCenter modal-90w"
    dialogClassName="forceCenter pt-5 mt-5"
  >
    <Modal.Body>
      <div style={{width: '75vw', height: "auto"}}>
      <div className="col-12 container">
        <div style={{ paddingTop: '56.25%' }} className="col-12">
          <iframe
            style={{
              position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, width: '100%', height: '100%',
            }}
            frameborder="0" 
            allowfullscreen
            autoplay={props.autoplay}
            title="Reservops.ai Slack Intrgration"
            src={props.src}
          />
        </div>
      </div>
      </div>
      {/* <div style={styles.iframe}>
      <iframe className="responsive-iframe" src="https://www.youtube.com/embed/KomAMNkclXc" title="MaxSpotter" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div> */}
    </Modal.Body>
    {/* <Modal.Footer>
      <Button onClick={props.onHide}>Close</Button>
    </Modal.Footer> */}
  </Modal>
  )
};