/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import purchasePlannerIcon from '../images/purchase-planner-icon.png';
import MLIcon from '../images/machine-learning-icon.png';


const WhyBusinessComponents6 = (props) => {

  const renderRegularCards = (
    <div className="row d-flex justify-content-center col-12 p-0 m-0">
        <div className="col-12 col-md-6 col-lg-3 pb-4">
          <div className="card pricing mx-3 ml-md-0 m-lg-0 h-100">
            <div className="col-12">
              <img width="100%" height="auto" alt="" src={purchasePlannerIcon}/>
              <h4 className="mb-4 mt4"><b>Purchase Planner</b></h4>
              <span>We use a dynamic programming approach to model all possible purchasing strategies and find you the plan with the optimal upfront spend, maximum savings, and least commitment.</span>
            </div>
          </div>
        </div>
        <div  className="col-12 col-md-6 col-lg-3 pb-4">
        <div className="card pricing mx-3 ml-md-0 m-lg-0 h-100">
            <div className="col-12">
              <img width="100%" height="auto" alt="" src={MLIcon}/>
              <h4 className="mb-4 mt-4"><b>ML Modeling Tools</b></h4>
              <span>Stop spending hours in spreadsheets evaluating purchasing options. ReservOps.ai models all potential scenarios using highly granular cost and utilization data and presents you with the best ones.</span>
            </div>
         </div>
        </div>
      </div>
);

  return (
    <div id="who-is-it-for" className="section bg-light">
    <div className="container">
      <div className="row">
        {props.headerText && <div style={{color: 'white'}} className="col-12 section-title text-center mb-5">
          <h3>{props.headerText}</h3>
          <p>
          </p>
        </div>}
        <div>
        {props.disabledCard > 0 ? renderRegularCards  : renderRegularCards}
        </div>
      </div>
    </div>
  </div>
  ); 
}

export default WhyBusinessComponents6;
