import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import React, { useState } from 'react';
// import onePagerSlackImg from '../images/slack_notifications_one_pager.png';


const styles = {
  platformLogoActive: {
    maxWidth: '100%',
    height: 'auto',
    cursor: 'pointer',
  },
  featureCard: {
    cursor: 'pointer',
    textAlign: 'left',
  }
};

// const dataSlack={
//   image: onePagerSlackImg,
// };

const features = [
  {
    title: 'AI Assistant',
    description: 'Save %35 in average within 2 minutes with AI Assistant',
    component: (<div className="w-100">
    <div className="col-12 container">
      <div style={{ paddingTop: '56.25%' }} className="col-12">
        <iframe
          style={{
            position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, width: '100%', height: '100%',
          }}
          frameborder="0" 
          allowfullscreen
          title="Optimum Reservations and Commitments with AI Assistant"
          src={'https://www.youtube.com/embed/FF9qxp-vAC0'}
        />
      </div>
    </div>
  </div>)
  },
  {
    title: 'Reservations',
    description: 'Reservation planning, guaranteed buy-back, 7/24 optimization in terms of utilization and cost',
    component: (<div className="w-100">
    <div className="col-12 container">
      <div style={{ paddingTop: '56.25%' }} className="col-12">
        <iframe
          style={{
            position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, width: '100%', height: '100%',
          }}
          frameborder="0" 
          allowfullscreen
          title="Optimum Reservations and Commitments with AI Assistant"
          src={'https://www.youtube.com/embed/4A3LNOMi2vA'}
        />
      </div>
    </div>
  </div>)
  },
  {
    title: 'Actionable Slack Notifications',
    description: 'Slacks and Opsgenie notifications for actionable alarms',
    component: (<div className="w-100">
    <div className="col-12 container">
      <div style={{ paddingTop: '56.25%' }} className="col-12">
        <iframe
          style={{
            position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, width: '100%', height: '100%',
          }}
          frameborder="0" 
          allowfullscreen
          title="Slack Integration"
          src={'https://www.youtube.com/embed/MV2ckLr0P1U'}
        />
      </div>
    </div>
  </div>)
  },
  {
    title: 'Cost Segments and Budget',
    description: 'See, understand, cloud infrastructure costs, set budget alarms',
    component: (<div className="w-100">
    <div className="col-12 container">
      <div style={{ paddingTop: '56.25%' }} className="col-12">
        <iframe
          style={{
            position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, width: '100%', height: '100%',
          }}
          frameborder="0" 
          allowfullscreen
          title="Cost Segments and Budgets"
          src={'https://www.youtube.com/embed/kkvVtp5VFVM'}
        />
      </div>
    </div>
  </div>)
  },
  {
    title: 'Anomaly Detector',
    description: 'Detect idle resources or high cost peaks instantly',
    component: (<div className="w-100">
      <div className="col-12 container">
        <div style={{ paddingTop: '56.25%' }} className="col-12">
          <iframe
            style={{
              position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, width: '100%', height: '100%',
            }}
            frameborder="0" 
            allowfullscreen
            title="Anomaly Detection and Idle Resources"
            src={'https://www.youtube.com/embed/kkvVtp5VFVM'}
          />
        </div>
      </div>
    </div>)
  },
];

const KeyFeaturesComponent = (props) => {  

  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0);

  const  handleFeatureClick = (index) => (event) => {
    setSelectedFeatureIndex(index);
  } 

  return (
    <div className="row col-12 p-0 m-0">
      {/* <img 
        style={{...styles.platformLogoActive, cursor: 'unset'}}
        src={onePagerImg}  
        alt='Reservops.ai SaaS Platform'
        // width='60%'
      /> */}
      <div className="col col-12 col-lg-5">
        {features.map((item, index) => (
        <div className="col-12">
        <div
          style={styles.featureCard} 
          onClick={handleFeatureClick(index)} 
          className={`card ${index === selectedFeatureIndex ? 'card-selected' : ''} pricing mx-0 my-0`}
        >
          <h5 className="mb-auto">{item.title}</h5>
          {(item.description && item.description.length > 0) /*&&
            <p className="d-inline-flex">{item.description}</p>*/
          }
        </div>
      </div>
        // <div style={{cursor: 'pointer'}} onClick={handleFeatureClick(index)} className="col-12">{item.title}</div>
        ))}
      </div>
      <div className="col col-12 col-lg-7 d-none d-lg-flex">
        <div className="d-inline-flex w-100">
        {features && features[selectedFeatureIndex] 
          ? (features[selectedFeatureIndex].component || <div>Coming soon</div>)
          : <div>Coming soon</div>
        }
        </div>
      </div>
    </div>
  );
}

export default KeyFeaturesComponent;
