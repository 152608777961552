import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PlatformSelector from '../components/PlatformSelector';
import { Link, useLocation } from 'react-router-dom';

const styles= {
  headingContainer: {
    margin: '4em auto'
  },
  sliderTitle: {
    textAlign: 'center',
    marginBottom: '2em',
  },
  nextBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2em'
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const EarlyAccess = (props) => {
  const query = useQuery();
  const origin = query.get('origin');
  const type = query.get('type');
  const [platform, setPlatform] = useState('AWS');
  const [otherPlatform, setOtherPlatform] = useState('');
  
  const handleOtherPlatformChange = (value) => {
    setOtherPlatform(value);
  }

  const handlePlatformChange = (key) => {
    setPlatform(key);
  }

  return (
    <div>
      <Header
        sectionHeading='Select Your Cloud Platform' 
        sectionBodyText='Which Cloud Platform Do You Use?'
      />
      <div className="d-flex container  justify-content-center align-items-center" style={styles.headingContainer}>
        <div className="col-8">
        
        <PlatformSelector forBeta={true} platform={platform} setPlatform={handlePlatformChange} setOtherPlatform={handleOtherPlatformChange}></PlatformSelector>
        <Link to={`/get-started-cost?platform=${otherPlatform.length > 0 ? otherPlatform : platform}&instanceCount=222&origin=${origin}${type ? `&type=${type}` : ''}`}>
        <button 
            style={styles.nextBtn} 
            className="btn btn-xl btn-outline-primary-maxspotter mx-auto mb-3"
          >Next</button>
        </Link> 
        </div>
      </div>
      <Footer></Footer>
    </div>
  ); 
}

export default EarlyAccess;
