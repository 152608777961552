import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

export default function withScrollToTop(WrappedComponent) {
  class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
      if (this.props.location.hash) {
        const id = this.props.location.hash.substr(1);
        if (!id) {
          return
        }
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView()
        }
      } else if (this.props.location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  ScrollToTop.propTypes = {
    location: PropTypes.object.isRequired,
  };

  return withRouter(ScrollToTop);
}
