import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PlatformSelector from '../components/PlatformSelector';
import { Link } from 'react-router-dom';

const styles= {
  headingContainer: {
    margin: '4em auto'
  },
  sliderTitle: {
    textAlign: 'center',
    marginBottom: '2em',
  },
  nextBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2em'
  }
}

const FreeCredits = (props) => {
  const [platform, setPlatform] = useState('AWS');
  const [otherPlatform, setOtherPlatform] = useState('');
  
  const handleOtherPlatformChange = (value) => {
    setOtherPlatform(value);
  }

  const handlePlatformChange = (key) => {
    setPlatform(key);
  }

  return (
    <div>
      <Header
        sectionHeading='$2,500 Free Credits to Survive COVID-19 Pandemic'
        sectionBodyHeading='Get Started to Reduce Your Costs for Free!'
        sectionBodyText= "In the days of the global pandemic we want everyone to survive. What we can help is to reduce your AWS bills, so we are giving out $2,500 free of use. This means we won't charge until we save you $2,500 in your AWS costs. "
      />
      <div className="d-flex container  justify-content-center align-items-center" style={styles.headingContainer}>
        <div className="col-8">
        <h4 style={styles.sliderTitle}>{`Which cloud platform do you use?`} </h4>
        <PlatformSelector forBeta={true} platform={platform} setPlatform={handlePlatformChange} setOtherPlatform={handleOtherPlatformChange}></PlatformSelector>
        <Link to={`/get-started-instance?origin=free-credits&platform=${otherPlatform.length > 0 ? otherPlatform : platform}`}>
        <button 
            style={styles.nextBtn} 
            className="btn btn-xl btn-outline-primary-maxspotter mx-auto mb-3"
          >Next</button>
        </Link> 
        </div>
      </div>
      <Footer></Footer>
    </div>
  ); 
}

export default FreeCredits;
