/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import hourlyMonitoringIcon from '../images/hourly-monitoring-icon.jpg';
import chatAlertIcon from '../images/chat-alert-icon.jpg';

const WhyBusinessComponents5 = (props) => {

  const renderRegularCards = (
    <div className="row d-flex justify-content-center col-12 p-0 m-0">
        <div className="col-12 col-md-6 col-lg-3 pb-4">
          <div className="card pricing mx-3 ml-md-0 m-lg-0 h-100">
            <div className="col-12">
              <img width="100%" height="auto" alt="" src={hourlyMonitoringIcon}/>
              <h4 className="mb-4 mt-4"><b>Hourly Monitoring</b></h4>
              <span>Keep a pulse on your costs with real-time monitoring to identify & stop cost anomalies and avoid nasty invoice surprises.</span>
            </div>
          </div>
        </div>
        <div  className="col-12 col-md-6 col-lg-3 pb-4">
        <div className="card pricing mx-3 ml-md-0 m-lg-0 h-100">
            <div className="col-12">
              <img  width="100%" height="auto" alt="" src={chatAlertIcon}/>
              <h4 className="mb-4 mt-4"><b>Integrated Alerting</b></h4>
              <span>Receive customized alerts for cost anomalies, savings opportunities, and other actionable items via email or Slack.</span>
            </div>
         </div>
        </div>
      </div>
);

  return (
    <div id="who-is-it-for" className="section bg-light">
    <div className="container">
      <div className="row">
        {props.headerText && <div style={{color: 'white'}} className="col-12 section-title text-center mb-5">
          <h3>{props.headerText}</h3>
          <p>
          </p>
        </div>}
        <div>
        {props.disabledCard > 0 ? renderRegularCards  : renderRegularCards}
        </div>
      </div>
    </div>
  </div>
  ); 
}

export default WhyBusinessComponents5;
