import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import StatsSection from '../components/StatsSection';

const WhoIsItFor = (props) => {
  return (
    <div>
      <Header 
        sectionHeading='Who is it for?' 
        sectionBodyHeading='We Help AnyOne on the Cloud, SaaS, PaaS Platforms, Websites and More'
        sectionBodyText='No matter your cloud workload is, our software can help you minimize your infrastructure costs without any service interruptions'
      />
      <div className="my-lg ">
      <div className="container text-center align-items-center">
        <h4 className="mb-4">
          Cloud Solutions
        </h4>
        <div className="container col-sm-9 mb-5">
          <p>
          Spot instances are cheap but they can be terminated by AWS with 2 minutes notice. 
          However, MaxSpotter prediction algorithms continously measures and predicts termination likelihood. 
          Before the termination notice by AWS, it auto replaces existing spot instances with new ones for service continuity.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-6 text-left">
            <h4>SaaS Platforms, CI/CD Servers</h4>
            <p>
              You don't need to worry about your existing servers. MaxSpotter supports opt-in or opt-out mode. 
              MaxSpotter converts your existing servers in AutoScaling groups within minutes gracefully.
            </p>
          </div>
          <div className="col-lg-6 text-left">
            <h4>PaaS, Big Data, Data Analytics Platforms</h4>
            <p>
              Any workload on the cloud can be run on cheap spot servers, if your servers are not single point of failures.
              This is our philosophy.  
            </p>
          </div>
        </div>
      </div>
    </div>
    <StatsSection disabledCard={0} headerText={'Who Is It For?'}></StatsSection>

    <Footer></Footer>
    </div>
  );
}

export default WhoIsItFor;