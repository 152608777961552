/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const styles = {
  cookieConsentContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 99999,
    opacity: .9,
    boxShadow: '0  2px  4px 0 black'
    }
}

const CookieConsent = (props) => {
  const cookieConsent = JSON.parse(localStorage.getItem('cookieConsent')) || {};
  const [cookieConsentOk, setCookieConsentOk ] =useState(cookieConsent.isOk || false );
  
  const handleCookieConsentOk = () => {
    setCookieConsentOk(true);
    localStorage.setItem('cookieConsent', JSON.stringify({isOk: true}));
  }

  
  useEffect(() =>  {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (cookieConsent && cookieConsent.isOk) {
      setCookieConsentOk(true);
    }
  }, []);

  if(cookieConsentOk) {
    return <div />;
  }
  
  return (
    <div style={styles.cookieConsentContainer} className="col-12  w-100 bg-gradient-primary p-2">
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-12 col-md-9 col-lg-6 row d-flex justify-content-center align-items-center">
        <div className="col-12 col-md-8 mb-2 mb-md-0">
          <p className="text-white p-0 m-0">
            We use cookies to better understand your needs and provide 
            you with personalised content. <Link to='/cookie-policy' target="blank">
              <span style={{textDecoration: 'underline'}} className='text-white'>Learn More</span>
              </Link>  
            </p>
        </div>
        <div className="col-12 col-md-4 text-md-right mb-2 mb-md-0">
          <button onClick={handleCookieConsentOk} className="btn btn-outline-light btn-sm w-100">Ok I got it</button>
        </div>
        </div>
      </div>
    </div>
  ); 
}

export default CookieConsent;
